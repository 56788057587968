import usePrevious from 'hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'routes/const';
import { clear } from 'store/reducers/cart';


export function RequireAccountCartToHaveItems({ children, redirectTo = ROUTES.STORE.INDEX }) {
    const { list } = useSelector((state) => state.cart);

    if (list.length === 0) {
        return <Navigate to={redirectTo} replace />;
    }

    return children;
}
