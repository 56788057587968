import { styled } from '@mui/material';

export const SimpleList = styled('div')({
    margin: 0,
    padding: 0,
    overflow: 'hidden'
});

export const SimpleListItem = styled('div')({
    display: 'inline-block',
    padding: '0 15px',
    marginLeft: '10px',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#151F57',
    position: 'relative',
    '&:first-child': {
        marginLeft: 0
    },
    '&:before': {
        position: 'absolute',
        left: 0,
        top: '45%',
        content: '""',
        borderRadius: '50%',
        display: 'inline-block',
        width: '5px',
        height: '5px',
        backgroundColor: '#151F57'
    }
});