import PropTypes from "prop-types";
import { Autocomplete, Box, CircularProgress, InputAdornment, Skeleton, Stack, styled, TextField } from "@mui/material";
import MainCard from "components/MainCard";
import { useEffect, useMemo, useState } from "react";
import { AccountSelect } from "components/account-select/AccountSelect";
import { useDispatch } from "react-redux";
import { setSelected } from "store/reducers/account";
import { debounce } from "@mui/material/utils";
import ClientsService from "services/clients/Clients";
import OnechipsService from "services/onechips/Onechips";
import { useAsyncNew } from "hooks/useAsyncNew";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router";
import { ROUTES } from "routes/const";
import Breadcrumbs from "components/breadcrumb";
import useStateAsync from "hooks/useStateAsync";
import { setFilters } from "store/reducers/onechips";
import loglevel from 'loglevel';
import withLoader from "hoc/witLoader";
import { LoadingOutlined } from "@ant-design/icons";
import { trim } from "lodash";
import OnechipsSearch from "components/top-section/components/OnechipsSearch";
import OnechipsSearchContainer from "components/top-section/containers/OnechipsSearchContainer";
import AccountSelectContainer from "components/top-section/containers/AccountSelectContainer";

const mainCardStyles = {
    height: '64px',
    position: 'relative',
    minWidth: '268px',
    backgroundColor: '#fff',
    mb: 2
};

const mainCardContentStyles = {
    display: 'flex',
    height: '100%',
    padding: '0 20px'
};


const TopSection = ({ children }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const clients = useStateAsync(ClientsService.getClientsList, { clientQueryParameterName: "clientIds", immediate: false });

    useEffect(() => {
        clients.execute({ 'sort.name': "ASC" });
    }, []);


    const showContent = useMemo(() => {
        if (clients.isSuccess || !clients.isInitialRequest) {
            return true;
        }
        return false;
    }, [clients])

    return (
        <>
            <MainCard contentSX={mainCardContentStyles} sx={mainCardStyles}>
                <Stack direction="row" alignItems="center" style={{ width: '100%' }} justifyContent="space-between">
                    <Breadcrumbs />
                    <Stack direction="row" justifySelf={'end'} spacing={6} alignItems="center" justifyContent="end">
                        <OnechipsSearchContainer />
                        <AccountSelectContainer />
                    </Stack>
                </Stack>
            </MainCard>
            {showContent ?
                <>
                    {children}
                </>
                : <LoadingOutlined style={{ fontSize: '36px', color: '#08c' }} />
            }
        </>
    );
};


export default TopSection;
