import { createSlice, current } from '@reduxjs/toolkit';


// initial state
const initialState = {
    selected: {}
};


const account = createSlice({
    name: 'account',
    initialState,
    reducers: {

        setSelected(state, action) {
            state.selected = action.payload.selected;
        },

        reset(state, action) {
            state.selected = initialState.selected;
        }
    }
});

export default account.reducer;

export const { setSelected, reset } = account.actions;
