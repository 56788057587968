import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { RequireAuthentication } from "./guards/RequireAuthentication";
import { RequireAuthorizedAccess } from "./guards/RequireAuthorizedAccess";
import Permissions from "const/Permissions";
import { ROUTES } from "./const";
import { RequirePasswordReset } from "./guards/RequirePasswordReset";
import NotFound from "components/Error/NotFound";
import { RequireTokenSignIn } from "./guards/RequireTokenSignIn";
import { RequireAccountCartToHaveItems } from "routes/guards/RequireAccountCartToHaveItems";
import { RequireAccountSelection } from "routes/guards/RequireAccountSelection";
import { RequireLoginView } from "routes/guards/RequireLoginView";
import { RequireClearCartOnAccountChange } from "routes/guards/RequireClearCartOnAccountChange";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('modules/dashboard/index.js')));

const RequireAccountEid = Loadable(lazy(() => import('routes/guards/RequireAccountEid')));

// render - Onechip details
const OneChipDetailsdDefault = Loadable(lazy(() => import('modules/onechip-details/index.js')));
const OneChipDetails_GeneralInformation = Loadable(lazy(() => import('modules/onechip-details/general-information')));
const OneChipDetails_NetworkReports = Loadable(lazy(() => import('modules/onechip-details/network-reports')));
const OneChipDetails_Feed = Loadable(lazy(() => import('modules/onechip-details/feed')));
const OneChipDetails_Notifications = Loadable(lazy(() => import('modules/notifications')));
const OneChipDetails_SendSms = Loadable(lazy(() => import('modules/onechip-details/send-sms')));
const OneChipDetails_Performance = Loadable(lazy(() => import('modules/onechip-details/euicc-performance')));
const OneChipDetails_Meerkat = Loadable(lazy(() => import('modules/onechip-details/meerkat')));


const OnechipCardsDefault = Loadable(lazy(() => import('modules/onechip-cards/index.js')));
const NetworksApps = Loadable(lazy(() => import('modules/networksapps/index.js')));
const AddOns = Loadable(lazy(() => import('modules/add-ons')));
const AddOnsList = Loadable(lazy(() => import('modules/add-ons/AddOnsList')));
const AddOnsProductsList = Loadable(lazy(() => import('modules/add-ons/AddOnsProductsList')));
const AddOnsOrderSuccess = Loadable(lazy(() => import('modules/add-ons/OrderSuccess')));
// render - user section
const UserMenu = Loadable(lazy(() => import('modules/my-user')));
const UserInfo = Loadable(lazy(() => import('modules/my-user/user-info')));
const UserApi = Loadable(lazy(() => import('modules/my-user/api')));
const UserInvoiceHistory = Loadable(lazy(() => import('modules/my-user/invoice-history/containers/InvoiceHistory')));

// render - store section
const StoreMenu = Loadable(lazy(() => import('modules/store')));
const StoreOnechip = Loadable(lazy(() => import('modules/store/onechip-store')));
const StoreCheckout = Loadable(lazy(() => import('modules/store/checkout')));
const StoreCheckoutError = Loadable(lazy(() => import('modules/store/checkout/OrderError')));
const StoreCheckoutuccess = Loadable(lazy(() => import('modules/store/checkout/CheckoutSuccess')));

const OrderHistory = Loadable(lazy(() => import('modules/store/order-history')));
const PaymentHistory = Loadable(lazy(() => import('modules/store/payment-history')));


const Support = Loadable(lazy(() => import('modules/support')));

// ==============================|| MAIN ROUTING ||============================== //



const MainRoutes = {
    path: ROUTES.DASHBOARD,
    element: (
        <RequireTokenSignIn>
            <RequireLoginView>
                <RequirePasswordReset>
                    <RequireClearCartOnAccountChange>
                        <MainLayout />
                    </RequireClearCartOnAccountChange>
                </RequirePasswordReset>
            </RequireLoginView>
        </RequireTokenSignIn>
    ),
    children: [
        {
            index: true,
            element: <DashboardDefault />
        },
        {
            path: ROUTES.NETWORK_APPS,
            element: (
                <RequireAccountSelection>
                    <RequireAuthorizedAccess permissions={Permissions.MarketplaceSection.ViewNetworkApps}>
                        <NetworksApps />
                    </RequireAuthorizedAccess>
                </RequireAccountSelection>
            )
        },
        {

            path: ROUTES.ADD_ONS.INDEX,
            element: (
                <RequireAuthorizedAccess permissions={Permissions.AddonsSection.ViewAddons}>
                    <AddOns />
                </RequireAuthorizedAccess >
            ),
            children: [
                {
                    index: true,
                    element: (
                        <AddOnsList />
                    )
                },
                {
                    path: ROUTES.ADD_ONS.ADD_ON,
                    breadcrumb: null,
                    element: (
                        <AddOnsProductsList />
                    )
                },
                {
                    breadcrumb: null,
                    path: ROUTES.ADD_ONS.ADD_ON_ORDER_SUCCESS,
                    element: <AddOnsOrderSuccess />
                },
            ]
        },
        {

            path: ROUTES.FEED,
            element: (
                <OneChipDetails_Notifications />
            )
        },
        {
            path: ROUTES.ONECHIPS,
            children: [
                {
                    breadcrumb: 'Back to OneChip list',
                    index: true,
                    element: (
                        <RequireAuthorizedAccess permissions={Permissions.OnechipCardsSection.ViewOnechipCards}>
                            <OnechipCardsDefault />
                        </RequireAuthorizedAccess>
                    )
                },
                {
                    path: ROUTES.ONECHIP_DETAILS.INDEX,
                    element: <OneChipDetailsdDefault />,
                    children: [
                        {
                            index: true,
                            element: <OneChipDetails_GeneralInformation />
                        },
                        {
                            breadcrumb: null,
                            path: ROUTES.ONECHIP_DETAILS.PERFORMANCE,
                            element: <OneChipDetails_Performance />
                        },
                        {
                            breadcrumb: null,
                            path: ROUTES.ONECHIP_DETAILS.REPORTS,
                            element: <OneChipDetails_NetworkReports />
                        },
                        {
                            breadcrumb: null,
                            path: ROUTES.ONECHIP_DETAILS.FEED,
                            element: <OneChipDetails_Feed />
                        },
                        {
                            breadcrumb: null,
                            path: ROUTES.ONECHIP_DETAILS.MEERKAT,
                            element: (
                                <RequireAuthorizedAccess permissions={Permissions.MeerkatSection.ViewMeerkat}>
                                    <OneChipDetails_Meerkat />
                                </RequireAuthorizedAccess >
                            )
                        },
                        {
                            breadcrumb: null,
                            path: ROUTES.ONECHIP_DETAILS.SEND_SMS,
                            element: <OneChipDetails_SendSms />
                        }
                    ]
                },
            ]
        },
        {
            path: ROUTES.USER.INDEX,
            element: <UserMenu />,
            children: [
                {
                    index: true,
                    element: <UserInfo />
                },
                {
                    breadcrumb: null,
                    path: ROUTES.USER.API,
                    element: <UserApi />,
                },
                {
                    breadcrumb: null,
                    path: ROUTES.USER.INVOICE_HISTORY,
                    element: (
                        <RequireAuthorizedAccess permissions={Permissions.UserSection.ViewInvoiceHistory}>
                            <UserInvoiceHistory />
                        </RequireAuthorizedAccess >
                    )
                },
            ]
        },
        {
            path: ROUTES.STORE.INDEX,
            element: (
                <RequireAuthorizedAccess permissions={Permissions.StoreSection.ViewStore}>

                    <StoreMenu />

                </RequireAuthorizedAccess >
            ),
            children: [
                {
                    index: true,
                    element: <StoreOnechip />
                },
                {
                    breadcrumb: null,
                    path: ROUTES.STORE.CHECKOUT,
                    element: (
                        <RequireAccountCartToHaveItems>
                            <RequireAccountSelection>

                                <StoreCheckout />

                            </RequireAccountSelection>
                        </RequireAccountCartToHaveItems>
                    )
                },
                {
                    breadcrumb: null,
                    path: ROUTES.STORE.CHECKOUT_ERROR,
                    element: <StoreCheckoutError />
                },
                {
                    breadcrumb: null,
                    path: ROUTES.STORE.CHECKOUT_SUCCESS,
                    element: <StoreCheckoutuccess />
                },
                {
                    breadcrumb: null,
                    path: ROUTES.STORE.ORDER_HISTORY,
                    element: <OrderHistory />,
                },
                {
                    breadcrumb: null,
                    path: ROUTES.STORE.PAYMENT_HISTORY,
                    element: <PaymentHistory />,
                },
            ]
        },

        {

            path: ROUTES.SUPPORT,
            element: (
                <Support />
            )
        },
        {
            breadcrumb: null,
            path: ROUTES.NOT_FOUND,
            element: <NotFound />
        }
    ]
};

export default MainRoutes;
