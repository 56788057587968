import api from '../api';
import TokenService from 'services/token/Token';
import { ROUTES } from 'routes/const';

const login = async ({ email, password }) => {
    try {
        const { data } = await api.post('/auth/sign-in', {
            email,
            password
        }
        );
        const { data: userData, token, refreshToken } = data;
        TokenService.clear();
        return _processSignInData({ token, refreshToken }, userData);
    } catch (error) {
        throw error;
    }
}

const createLoginToken = async (adminId) => {
    const { data } = await api.get(`/api/admin/login-url/${adminId}`, {});
    return data.data;
}

const checkPassword = async (password) => {
    const { data } = await api.post('/api/check-password', { password });
    return data;
}


const logout = () => {
    TokenService.clear();
    window.location = ROUTES.LOG_IN;
};

const refreshAuth = async () => {
    try {
        const { data } = await api.post('/auth/refresh', {

            refresh_token: TokenService.getRefreshToken()

        });
        const { data: userData, token, refreshToken } = data;

        return _processSignInData({ token, refreshToken }, userData);
    } catch (error) {
        throw error;
    }
}

const resetPassword = async ({ email }) => {
    try {
        const { emailTemplate } = {
            emailTemplate: CONFIG.app.email_templates.reset_password,
        };
        const { data } = await api.post('/auth/reset-password', {
            email,
            emailTemplate
        }
        );
        return data;
    } catch (error) {
        throw error;
    }
};


const signInWithToken = async ({ loginToken }) => {
    try {
        const { data } = await api.post('/auth/sign-in-with-token', { loginToken });
        const { data: userData, token, refreshToken } = data;

        return _processSignInData({ token, refreshToken }, userData);
    } catch (error) {
        throw error;
    }
};

const _processSignInData = (tokens, userData) => {

    const { token, refreshToken } = tokens;

    const strToArray = (str, separator = ';') => {
        if (!str) return [];
        return str.split(separator).map(function (el) {
            return el.trim();
        });
    };

    const data = {
        ...userData,
        permissions: strToArray(userData?.permissions),
        billingProviderTypes: strToArray(userData?.billingProviderTypes),
    };
    const result = { data, token, refreshToken }
    TokenService.setData(result);
    return result;
};

const AuthService = {
    login,
    logout,
    refreshAuth,
    resetPassword,
    signInWithToken,
    checkPassword,
    createLoginToken
};

export default AuthService;
