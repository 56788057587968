// ==============================|| OVERRIDES - TABS ||============================== //

export default function Autocomplete(theme) {
    return {
        MuiAutocomplete: {
            styleOverrides: {
                fullwidth: {
                    height: "inherit"
                },
                inputRoot: {
                    height: "inherit"
                },
                paper: {
                    backgroundColor: "#fff"
                }
            }
        }
    };
}
