// ==============================|| OVERRIDES - TABS ||============================== //

export default function AppBar(theme) {
    return {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#4c5d87"
                }
            }
        }
    };
}
