// third-party
import { merge } from 'lodash';

// project import
import AppBar from './AppBar';

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme) {
    return merge(
        AppBar(theme)
    );
}
