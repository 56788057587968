// third-party
import { configureStore } from '@reduxjs/toolkit';
import Middlewares from 'store/middlewares';
import PreloadedStates from 'store/preloaded-states';
// project import
import reducers from './reducers';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //


const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...Middlewares),
    preloadedState: PreloadedStates,
});

const { dispatch } = store;

export { store, dispatch };
