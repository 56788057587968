// ==============================|| OVERRIDES - BUTTON ||============================== //
export default function Button(theme) {
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */ }
    const disabledStyle = {
        '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[200]
        }
    };

    return {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'contained' && {
                        backgroundColor: "#FFCC33 !important",
                    })
                })
            }
        }
    }
}
