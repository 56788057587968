import InterLight from 'assets/fonts/Inter-Light.ttf';
import InterRegular from 'assets/fonts/Inter-Regular.ttf';
import InterMedium from 'assets/fonts/Inter-Medium.ttf';
import InterSemiBold from 'assets/fonts/Inter-SemiBold.ttf';
import InterBold from 'assets/fonts/Inter-Bold.ttf';
import InconsolataRegular from 'assets/fonts/Inconsolata-Regular.ttf';
import InconsolataMedium from 'assets/fonts/Inconsolata-Medium.ttf';
import RobotoRegular from 'assets/fonts/Roboto-Regular.ttf';

export default function MuiCssBaseLine() {
  return {
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
              font-family: 'Inter-Regular';
              font-style: normal;
              font-display: swap;
              font-weight: 300;
              src: local('Inter-Regular'), local('Inter-Regular'), url(${InterLight}) format('truetype');
            }
            @font-face {
              font-family: 'Inter-Regular';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Inter-Regular'), local('Inter-Regular'), url(${InterRegular}) format('truetype');
            }
            @font-face {
              font-family: 'Inter-Regular';
              font-style: normal;
              font-display: swap;
              font-weight: 500;
              src: local('Inter-Regular'), local('Inter-Regular'), url(${InterMedium}) format('truetype');
            }
            @font-face {
              font-family: 'Inter-Regular';
              font-style: normal;
              font-display: swap;
              font-weight: 600;
              src: local('Inter-Regular'), local('Inter-Regular'), url(${InterSemiBold}) format('truetype');
            }
            @font-face {
              font-family: 'Inter-Regular';
              font-style: normal;
              font-display: swap;
              font-weight: 700;
              src: local('Inter-Regular'), local('Inter-Regular'), url(${InterBold}) format('truetype');
            }
            @font-face {
              font-family: 'Inconsolata';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Inconsolata'), local('Inconsolata'), url(${InconsolataRegular}) format('truetype');
            }
            @font-face {
              font-family: 'Inconsolata';
              font-style: normal;
              font-display: swap;
              font-weight: 500;
              src: local('Inconsolata'), local('Inconsolata'), url(${InconsolataMedium}) format('truetype');
            }
            @font-face {
              font-family: 'Roboto';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Roboto'), local('Roboto'), url(${RobotoRegular}) format('truetype');
            }
            html {
                scrollbar-color: #CCCCCC #ECECEC;
                scrollbar-width: thin;
              }
              
              /* WebKit and Chromiums */
              ::-webkit-scrollbar {
                width: 8px;
                height: 8px;
                background-color: #ECECEC;
              }
              
              ::-webkit-scrollbar-thumb {
                background: #CCCCCC;
                border-radius: 5px;
              }
          
            * {
              font-family: 'Inter-Regular';
            }
        `
    }
  };
}
