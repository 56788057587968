import { Box } from "@mui/material";
import { useAsyncNew } from "hooks/useAsyncNew";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "routes/const";
import AuthService from "services/auth/Auth";

const SignInWithToken = ({ loginToken }) => {
    const location = useLocation();
    const { execute, isError, isSuccess } = useAsyncNew(AuthService.signInWithToken, { immediate: false })

    useEffect(() => {
        if (loginToken) {
            execute({ loginToken })
        }
    }, [loginToken])

    if (isError) {
        return <Navigate to={ROUTES.LOG_IN} replace />;
    }
    if (isSuccess) {
        return <Navigate to={location.pathname} replace />;
    }
    return <Box>Please wait</Box>
}

export default SignInWithToken;