import TokenService from "services/token/Token";
import api from "../api";

const updatePassword = async (params) => {
  try {
    const { data } = await api.post("/api/updatepassword", params);
    return data;
  } catch (error) {
    throw error;
  }
};

const getUserInfo = async (params) => {
  const { data } = await api.get(`api/admin/info/me`, {
    params
  });
  return data;
};

const getPaymentDetails = async ({ clientId }) => {
  const { data } = await api.post(`api/get-payment-details`, {
    client: clientId
  });
  return data;
};

const addPaymentDetails = async (body) => {
  const { data } = await api.post(`api/add-payment-details`, body);
  return data;
};

const photoUpload = async ({ file, email }) => {
  const formData = new FormData();
  formData.append("file", file);
  const { data } = await api.post(`api/admin/avatar/${email}`, formData);
  return data;
};

const getAvatar = async () => {
  const user = TokenService.getData();
  const { data } = await api.get(`api/admin/avatar/${user.data.email}`);
  return data;
}

const removeAvatar = async ({ email }) => {
  const { data } = await api.delete(`api/admin/avatar/${email}`);
  return data;
};

const setDefaultPaymentMethod = async ({ clientId, creditCardIdentifier }) => {
  const { data } = await api.post(`api/set-default-payment-details`, {
    clientId: clientId,
    creditCardIdentifier: creditCardIdentifier
  });
  return data;
};

const removePaymentDetails = async ({ clientId, creditCardIdentifier }) => {
  const { data } = await api.post(`api/remove-payment-details`, {
    clientId: clientId,
    creditCardIdentifier: creditCardIdentifier
  });
  return data;
};

const getApiKeys = async () => {
  try {
    const { data } = await api.get("/api/getapikeys");
    return data;
  } catch (error) {
    throw error;
  }
};

const getNotificationsConfig = async () => {
  try {
    const { data } = await api.get("/api/notificationsconfig");
    return data;
  } catch (error) {
    throw error;
  }
};

const AccountService = {
  updatePassword,
  getUserInfo,
  getNotificationsConfig,
  getApiKeys,
  getPaymentDetails,
  setDefaultPaymentMethod,
  removePaymentDetails,
  addPaymentDetails,
  photoUpload,
  removeAvatar,
  getAvatar
};

export default AccountService;
