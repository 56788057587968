// third-party
import { merge } from 'lodash';
import Alert from './Alert';

// project import
import AppBar from './AppBar';
import Autocomplete from './Autocomplete';
import Badge from './Badge';
import Button from './Button';
import Card from './Card';
import CardContent from './CardContent';
import Checkbox from './Checkbox';
import Chip from './Chip';
import DataGridTableCell from './DataGridTableCell';
import IconButton from './IconButton';
import InputLabel from './InputLabel';
import LinearProgress from './LinearProgress';
import Link from './Link';
import List from './List';
import ListItemIcon from './ListItemIcon';
import MuiCssBaseLine from './MuiCssBaseLine';
import OutlinedInput from './OutlinedInput';
import Pagination from './Pagination';
import Paper from './Paper';
import Popover from './Popover';
import Tab from './Tab';
import TableCell from './TableCell';
import Tabs from './Tabs';
import Tooltip from './Tooltip';
import Typography from './Typography';

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme) {
    return merge(
        MuiCssBaseLine(),
        Alert(),
        Autocomplete(theme),
        Paper(),
        AppBar(theme),
        Popover(theme),
        List(theme),
        Button(theme),
        Badge(theme),
        Card(theme),
        CardContent(),
        Checkbox(theme),
        Chip(theme),
        IconButton(theme),
        InputLabel(theme),
        LinearProgress(),
        Link(),
        ListItemIcon(),
        OutlinedInput(theme),
        Tab(theme),
        TableCell(theme),
        Tabs(theme),
        Typography(),
        DataGridTableCell(theme),
        Tooltip(theme),
        Pagination(theme)
    );
}
