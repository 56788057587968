import { Button, Stack, Typography, Avatar, Box } from '@mui/material';

import ConfirmationModal from "components/shared/modal/ConfirmationModal";
import { Trans } from 'react-i18next';

const SelectClientConfirmationModal = ({ isOpen, onClose, text }) => {

    const renderActions = () => {
        return (
            <Box>
                <Stack direction={"row"} spacing={2}>
                    <Button style={{ width: "200px" }}
                        variant={"contained"}
                        onClick={onClose}
                    >
                        Ok
                    </Button>
                </Stack>
            </Box>
        );
    };

    return (
        <div>
            <ConfirmationModal
                isOpen={isOpen}
                onClose={() => onClose('OVERLAY')}
                renderActions={renderActions}
            >
                <Stack spacing={2} alignItems="center">
                    <Avatar sx={{ width: 88, height: 88, backgroundColor: "#FFEABF" }}>
                        <img
                            src="/warning_icon.svg"
                            alt="Warning"
                            style={{
                                width: '56px',
                                height: '56px',
                                color: '#FF4848',
                            }}
                        />
                    </Avatar>

                    <Typography sx={{ whiteSpace: "wrap" }}>
                        {text}
                    </Typography>
                </Stack>
            </ConfirmationModal>
        </div>
    );
};

export default SelectClientConfirmationModal;
