import usePermissions from 'hooks/usePermissions';
import TokenService from 'services/token/Token';
import Unauthorized from 'components/Error/Unauthorized';

export const RequireAuthorizedAccess = ({ permissions, children }) => {
    const methods = usePermissions();
    if (!methods.hasAny(permissions)) {
        return <Unauthorized />;
    }
    return children;
};
