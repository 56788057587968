import useTimeout from 'hooks/useTimeout';
import { useRef } from 'react';


const useFakeLoader = (durationInSeconds, defaultValue = true) => {
    const loading = useRef(defaultValue);

    useTimeout(() => {
        loading.current = !loading.current;
    }, durationInSeconds ? durationInSeconds * 1000 : null);

    return loading.current;
}

export default useFakeLoader;