import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout';
import { ROUTES } from './const';
import { RequireAuthentication } from './guards/RequireAuthentication';
import { RequirePasswordReset } from './guards/RequirePasswordReset';

// render - login
const AuthLogin = Loadable(lazy(() => import('../modules/authentication/Login')));
const AuthForgotPassword = Loadable(lazy(() => import('../modules/authentication/ForgotPassword')));

const AuthResetPassword = Loadable(lazy(() => import('../modules/authentication/ResetPassword')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: ROUTES.LOG_IN,
            element: (
                <RequireAuthentication redirectTo={ROUTES.DASHBOARD} isAuthenticated={false}>
                    <AuthLogin />
                </RequireAuthentication>
            )
        },
        {
            path: ROUTES.FORGOT_PASSWORD,
            element: (
                <RequireAuthentication isAuthenticated={false}>
                    <AuthForgotPassword />
                </RequireAuthentication>
            )
        },
        {
            path: ROUTES.RESET_PASSWORD,
            element: (
                <RequireAuthentication>
                    <AuthResetPassword />
                </RequireAuthentication>
            )
        }
    ]
};

export default LoginRoutes;
