// ==============================|| OVERRIDES - TABS ||============================== //

export default function List(theme) {
    return {
        MuiList: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff"
                }
            }
        }
    };
}
