// ==============================|| OVERRIDES - TABS ||============================== //

export default function Alert() {
    return {
        MuiAlert: {
            styleOverrides: {
                message: {
                    display: 'flex',
                    alignItems: 'center'
                },
                root: {
                    borderRadius: '8px',
                    color: '#111111'
                },
                standardSuccess: {
                    backgroundColor: '#EEFFE0'
                },
                standardError: {
                    backgroundColor: '#FFE5E5'
                },
                standardWarning: {
                    background: '#FFF2E0'
                },
                standardInfo: {
                    background: '#DDE3FA'
                }
            },

        }
    };
}
