import { createSlice, current } from '@reduxjs/toolkit';

const countNonEmptyFilters = (filters, initialVal) => {
    return Object.entries(filters).reduce((acc, [key, val]) => {
        if ((Array.isArray(val) && val.length === 0) || val === undefined || val === null || val === '') {
            return acc;
        }
        acc += Array.isArray(val) ? val.length : 1;
        return acc;
    }, initialVal);
}

// initial state
const initialState = {
    count: 0,
    filters: {
        list: {},
        offset: 0,
        sort: {
            timestamp: 'DESC'
        }
    }
};

const orderHistory = createSlice({
    name: 'orderHistory',
    initialState,
    reducers: {
        setFilters(state, action) {
            const { list = {}, ...restFilters } = action.payload;
            state.filters = { ...state.filters, list: { ...state.filters.list, ...list }, ...restFilters };
            state.count = countNonEmptyFilters(state.filters.list, initialState.count);
        },

        clearFilters(state) {
            state.filters = initialState.filters;
            state.count = initialState.count;
        }
    }
});

export default orderHistory.reducer;

export const { setFilters, clearFilters } = orderHistory.actions;
