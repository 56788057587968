import IconButton from '@mui/material/IconButton';

import { ROUTES } from 'routes/const';
import { useMatch, useNavigate } from "react-router";
import { SvgIcon } from '@mui/material';
import { ReactComponent as BellIcon } from 'assets/icons/bell_icon.svg';

export const Notifications = () => {
    const navigate = useNavigate();

    const feedPageMatch = useMatch(ROUTES.FEED);

    const handleButtonClick = () => {
        navigate(ROUTES.FEED)
    }

    return (
        <>
            <IconButton sx={{ mr: { md: 3, xs: 1 } }} onClick={handleButtonClick}>
                <SvgIcon component={BellIcon} style={{ color: feedPageMatch ? '#ffffff' : '#A1A5BC' }} inheritViewBox />
            </IconButton>
        </>
    );
};
