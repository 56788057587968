const CartStoreMiddleware = ({ getState }) => {
    return (next) => (action) => {
        const result = next(action);
        if (action.type.startsWith('cart/set')) {
            const { list, total } = getState().cart;
            sessionStorage.setItem('cart', JSON.stringify({ list, total }))
        }
        else if (action.type.startsWith('cart/remove')) {
            const { list, total } = getState().cart;
            sessionStorage.setItem('cart', JSON.stringify({ list, total }))
        }
        else if (action.type.startsWith('cart/clear')) {
            sessionStorage.removeItem('cart')
        }
        return result;
    };
};

export default CartStoreMiddleware;