import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import Header from 'components/header';

import { Divider } from '@mui/material';
import Footer from 'components/footer';
import TopSection from 'components/top-section/TopSection';
import { AvatarProvider } from 'contexts/AvatarContext';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ children }) => {
    return (

        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <AvatarProvider>
                <Header />
                <Box
                    sx={{
                        maxWidth: 'xl',
                        margin: '96px auto 0px auto',
                        width: '100%',
                        minHeight: 'calc(100vh - 280px)',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <TopSection>
                        {children}
                        <Outlet />
                    </TopSection>
                </Box>
            </AvatarProvider>
            <Box
                sx={{
                    height: '134px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    mt: 6,
                    boxSizing: 'content-box'
                }}
            >
                <Divider sx={{ color: '#ececec' }} />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'end',
                        justifyContent: 'center',
                        marginBottom: '40px',
                        marginTop: '24px'
                    }}
                >
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
};

export default MainLayout;
