import { Navigate } from "react-router-dom";
import { ROUTES } from "routes/const";
import TokenService from "services/token/Token";

export function RequirePasswordReset({ children }) {
    let authToken = TokenService.getData();

    if (authToken?.data?.forcePasswordChange === true) {
        return <Navigate to={ROUTES.RESET_PASSWORD} replace />;
    }
    return children;
}