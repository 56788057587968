import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import localizations from './localizations';
import { getStoreUnitFromBytes } from 'utils/functions';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: 'en',
        resources: {
            ...localizations
        }
    });

i18n.services.formatter.add('unit', (value, lng, options) => {
    const data = getStoreUnitFromBytes(value);
    return `${data.value} ${data.unit}`;
});

i18n.services.formatter.addCached('number_format', (lng, options) => {
    const formatter = new Intl.NumberFormat(lng, options);
    return (val) => formatter.format(val);
});

export default i18n;
