import useStateAsync from "hooks/useStateAsync";
import OnechipsService from "services/onechips/Onechips";
import { generatePath, useNavigate } from "react-router";
import { ROUTES } from "routes/const";
import OnechipsSearch from "components/top-section/components/OnechipsSearch";
import { useEffect } from "react";


const OnechipsSearchContainer = () => {
    const navigate = useNavigate();

    const onechips = useStateAsync(OnechipsService.getOnechipsSearchList, {
        immediate: false
    });

    const handleonSearch = (value) => {
        const values = value.split(/[,;\s]/g);
        if (values.length > 1) {
            return;
        }
        onechips.execute({ eid: values });
    };

    const handleOnSearchOptionSelect = (option, reason) => {
        if (reason !== "selectOption") return;
        const { eid = null } = option;
        navigate(generatePath(ROUTES.ONECHIP_DETAILS.INDEX, { eid }));
    };

    return <OnechipsSearch
        loading={onechips.isLoading}
        data={onechips?.data?.data}
        onSearch={handleonSearch}
        onSelect={handleOnSearchOptionSelect}
    />
};



export default OnechipsSearchContainer;
