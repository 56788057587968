// ==============================|| OVERRIDES - TABS ||============================== //

export default function AppBar(theme) {
    return {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.custom.mainColor
                }
            }
        }
    };
}
