import React from 'react';
import { Dialog, Typography, DialogActions, DialogContent, DialogTitle, Slide, IconButton } from '@mui/material';
import T from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { styled } from '@mui/material/styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '8px',
        p: 2
    },
    '& .MuiDialogActions-root': {
        gap: theme.spacing(1),
        marginLeft: '0 !important'
    }
}));

const ConfirmationModal = ({
    isOpen,
    renderActions,
    closeIcon,
    onClose,
    title,
    titleAlign,
    children,
    className,
    maxWidth,
    fullWidth,
    sx,
    mainIcon
}) => (
    <StyledDialog
        open={isOpen}
        transition={Transition}
        className={`confirmation-dialog ${className}`}
        fullWidth={fullWidth}
        keepMounted={false}
        maxWidth={maxWidth}
        onClose={onClose}
        sx={sx}
    >
        <DialogTitle sx={{ pt: 3, pb: 1 }} align={titleAlign}>
            <>
                {mainIcon ? <Typography component="img" src={mainIcon} sx={{ width: '88px', pb: 1 }} /> : null}
                {title ? <Typography variant="h4">{title}</Typography> : null}
                {closeIcon ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseOutlined />
                    </IconButton>
                ) : null}
            </>
        </DialogTitle>
        <DialogContent sx={{ pb: 3, pt: 0, overflow: 'visible' }} align="center">
            {children}
        </DialogContent>
        {renderActions &&
            <DialogActions sx={{ justifyContent: 'center', pb: 3, pt: 0 }}>{renderActions()}</DialogActions>
        }
    </StyledDialog>
);

ConfirmationModal.propTypes = {
    renderActions: T.func,
    isOpen: T.bool.isRequired,
    title: T.string.isRequired,
    onClose: T.func.isRequired,
    titleAlign: T.string,
    children: T.any,
    closeIcon: T.bool,
    className: T.string,
    maxWidth: T.string,
    fullWidth: T.bool,
    sx: T.object
};

ConfirmationModal.defaultProps = {
    renderActions: null,
    closeIcon: false,
    className: '',
    children: '',
    maxWidth: 'xs',
    fullWidth: true,
    titleAlign: 'center',
    sx: {}
};

export default ConfirmationModal;
