import { createTheme } from '@mui/material/styles';
import ComponentsOverrides from './components'

import Palette from './palette';
import Typography from './typography';
import Shadows from './shadows';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1266,
            xl: 1536
        }
    },
    direction: 'ltr',
    mixins: {
        toolbar: {
            minHeight: 60,
            paddingTop: 8,
            paddingBottom: 8
        }
    },
    typography: Typography(`Inter-Regular`),
    palette: Palette('light')
});


export default theme;