import PropTypes from "prop-types";
import {
    Avatar,
    Box,
    CircularProgress,
    Divider,
    FormControl,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Popover,
    Skeleton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import ExpandIconButton from "components/shared/buttons/ExpandIconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "components/shared/switch/Switch";

import { useTranslation } from "react-i18next";
import TokenService from "services/token/Token";

const ExpandFilterButton = ({ expand, onClick }) => (
    <Box sx={{ alignSelf: 'flex-start', width: '24px', height: '24px', pt: '2px' }}>
        <ExpandIconButton
            expand={expand}
            onClick={onClick}
            sx={{ border: expand ? '1px solid #151F57' : '1px solid #ECECEC', width: 'inherit', height: 'inherit', borderRadius: '4px' }}
        >
            <img src="/narrow_arrow_icon.svg" alt="" />
        </ExpandIconButton>
    </Box>
);

const createOptionConfig = (item, optionConfig) => {
    const fetchParam = (item, param) => (typeof param === 'function' ? param(item) : item[param]);
    const { id, text, image, uuid } = optionConfig;
    return {
        ...item,
        id: fetchParam(item, id),
        text: fetchParam(item, text),
        image: fetchParam(item, image),
        uuid: fetchParam(item, uuid),
    };
};

const Search = ({ sx, loading, onSearch, preventDefault }) => {

    const handleOnSearch = (event) => {

        if (event.which == 13) {

            onSearch(event.target.value);
        }
    };

    return (
        <>
            <FormControl sx={sx}>
                <TextField
                    sx={{ height: '40px', borderRadius: '8px', ...sx }}
                    size="small"
                    id="search"
                    fullWidth
                    onKeyPress={handleOnSearch}
                    aria-describedby="header-search-text"
                    inputProps={{
                        'aria-label': 'weight',
                        sx: { height: '40px', borderRadius: '8px', py: 0, ...sx }
                    }}
                    InputProps={{
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            </>
                        ),
                        startAdornment:
                            <>
                                <img src="/search_icon.svg" alt="" />
                            </>

                    }}
                    placeholder="Type to search..."
                />
            </FormControl>
        </>
    );
};


export const SelectList = ({ data, selected, optionConfig, onSearch, loading, onSelect }) => {
    const { t } = useTranslation();

    const listData = useMemo(() => {
        return data.map((item) => createOptionConfig(item, optionConfig));
    }, [data]);

    const handleOnSelect = (item) => {
        onSelect(item);
    };

    return (
        <>
            <Box sx={{ mb: 1, mt: 2 }}>
                <Search sx={{ width: '100%' }} onSearch={onSearch} loading={loading} />
            </Box>
            <Box>
                {listData.length > 0 && (
                    <List
                        sx={{
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 300
                        }}
                    >
                        {listData.map((item) => (
                            <ListItem sx={{ pl: 0, pr: 0 }} key={item.id}>
                                <ListItemButton
                                    selected={selected?.includes(item.id)}
                                    sx={{ p: 0 }}
                                    onClick={(event) => handleOnSelect(item)}
                                >
                                    <ListItemText primary={item.text} secondary={null} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                )}
                {loading && <Typography sx={{ textAlign: 'center', mt: 2 }}>Loading...</Typography>}
                {listData.length === 0 && !loading && (
                    <Typography sx={{ textAlign: 'center', mt: 2 }}>{t('common.top_bar.account.not_found')}</Typography>
                )}
            </Box>
        </>
    );
};

const AccountsList = ({ data, selectedItem, loading, onSelect }) => {
    const { t } = useTranslation();

    const handleOnSelect = (item, type) => {
        onSelect(item, type);
    };

    const getFirstLetter = (text) => {
        return (text || '').charAt(0).toUpperCase();
    }

    return (
        <Box sx={{ mt: '15px', width: '305px' }}>

            <Box>
                {data.length > 0 && (
                    <List
                        sx={{
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 300
                        }}
                    >
                        {data.map((item, index) => (
                            <ListItem sx={{ pl: 0, pr: 0 }} key={`account-${item.id}`}>
                                <ListItemButton
                                    selected={selectedItem === item}
                                    sx={{ p: 0 }}
                                    onClick={(event) => handleOnSelect(item, 'single')}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            src={item.image}
                                            sx={{
                                                width: '32px',
                                                height: '32px',
                                                border: '1px solid #ECECEC',
                                                p: '2px',
                                                boxSizing: 'content-box'
                                            }}
                                        >
                                            {getFirstLetter(item.text)}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={item.text} secondary={null} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                )}
                {data.length === 0 && !loading && (
                    <Typography sx={{ textAlign: 'center', mt: 4 }}>{t('common.top_bar.account.not_found')}</Typography>
                )}
            </Box>
        </Box>
    );
};

export const AccountSelect = ({ data, loading, isInitiallyLoaded, optionConfig, onSearch, onSelect }) => {
    const { t } = useTranslation();

    const selectAllData = { id: null, text: t('common.top_bar.account.all_accounts'), image: null, multiple: true };

    const [selected, setSelected] = useState(selectAllData);

    const [checked, setChecked] = useState(false);


    const accountData = useMemo(() => {
        return data.map((item) => createOptionConfig(item, optionConfig));
    }, [data]);

    const initialAccountsCount = (isInitiallyLoaded && accountData.length) || 0;

    useEffect(() => {
        if (isInitiallyLoaded) {
            const defaultSelected = initialAccountsCount === 1 ? accountData[0] : selectAllData;
            setSelected(defaultSelected);
            setChecked(defaultSelected?.id === null);
        }
    }, [isInitiallyLoaded, accountData]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearch = (value) => {
        onSearch(value);
    };

    const handleSelect = (value, type) => {

        onSelect(value, type);
        setSelected(value);
        setChecked(type === 'all');
    };

    const handleChange = (event) => {
        const { checked } = event.target;
        if (checked) {
            handleSelect(selectAllData, 'all');
        } else {
            handleSelect(accountData[0], 'single')
        }
    };

    const getFirstLetter = (text) => {
        return (text || '').charAt(0).toUpperCase();
    }

    return (
        <Stack direction="row" spacing={3} alignItems="center" justifyContent="end">
            {isInitiallyLoaded && loading ? (
                <Skeleton variant="circular">
                    <Avatar style={{ width: '32px', height: '32px' }}></Avatar>
                </Skeleton>
            ) : (
                <Avatar
                    src={selected?.image}
                    sx={{ width: '32px', height: '32px', border: '1px solid #ECECEC', p: '2px', boxSizing: 'content-box' }}
                >
                    {getFirstLetter(selected?.text)}
                </Avatar>
            )}
            {isInitiallyLoaded && loading ? (
                <Skeleton variant="rectangular" width={'131px'}>
                    <Box alignItems="left" width={'100%'} height={'40px'} direction="column">
                    </Box>
                </Skeleton>
            ) : (
                <>
                    <Box>
                        <Typography paragraph sx={{ mb: 0, color: '#7A7B84' }}>
                            {t('common.top_bar.account.title')}
                        </Typography>
                        <Typography
                            sx={{ color: '#111', width: '80px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', mr: 1 }}
                        >
                            {selected?.text}
                        </Typography>
                    </Box>
                    <ExpandFilterButton expand={open} onClick={handleClick} />

                    <Popover
                        id="accounts-popover"
                        open={open}
                        keepMounted
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        sx={{ mt: 4, '& .MuiPopover-paper': { p: '16px' } }}
                    >
                        <Box sx={{ mb: 0 }}>
                            <List
                                sx={{
                                    p: 0,
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: 300
                                }}
                            >
                                <ListItem sx={{ p: 0 }}>
                                    <ListItemAvatar>
                                        <Avatar
                                            src={selected?.image}
                                            sx={{ width: '32px', height: '32px', border: '1px solid #ECECEC', p: '2px', boxSizing: 'content-box' }}
                                        >
                                            {getFirstLetter(selected?.text)}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={selected?.text} secondary={null} />
                                </ListItem>
                            </List>
                        </Box>
                        {(TokenService.getData()?.data?.superAdmin || initialAccountsCount !== 1) &&
                            <Box sx={{ mt: 2 }}>
                                <Divider />
                                <Box sx={{ my: 1 }}>
                                    <FormControlLabel
                                        control={<Switch sx={{ m: 1 }} checked={checked} onChange={handleChange} />}
                                        label={t('common.top_bar.account.select_all')}
                                    />
                                </Box>
                                <Divider />
                                <Box sx={{ mb: 1, mt: 2 }}>
                                    <Search sx={{ width: '100%' }} onSearch={handleSearch} loading={loading} />
                                </Box>
                                <AccountsList
                                    data={accountData}
                                    selectedItem={selected}
                                    onSelect={handleSelect}
                                    loading={loading}
                                />
                            </Box>
                        }
                    </Popover>
                </>
            )}
        </Stack>
    );
};

AccountSelect.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    optionConfig: PropTypes.shape({
        id: PropTypes.oneOfType(PropTypes.string, PropTypes.func).isRequired,
        text: PropTypes.oneOfType(PropTypes.string, PropTypes.func).isRequired,
        image: PropTypes.oneOfType(PropTypes.string, PropTypes.func).isRequired
    }).isRequired,
    loading: PropTypes.bool,
    onSearch: PropTypes.func,
    onSelect: PropTypes.func
};

AccountSelect.defaultProps = {
    loading: false,
    onSearch: () => { },
    onSelect: () => { }
};
