import { useState } from 'react';
import { Box, Badge, Popover, IconButton, Divider, Button, Stack, Typography } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import { remove } from 'store/reducers/cart';

import { useTranslation } from 'react-i18next';

import { SvgIcon } from '@mui/material';
import { ReactComponent as BasketIcon } from 'assets/icons/basket_icon.svg';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/const';
import SelectClientConfirmationModal from 'components/modals/SelectClientConfirmationModal';


const CartList = ({ list }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleRemoveItem = (id) => {
        dispatch(remove({ id }));
    };

    return (
        <>
            <List>
                {list.length === 0 ? (
                    <ListItem>
                        <ListItemText primary={t('header.cart.no_items')} />
                    </ListItem>
                ) : (
                    list.map((item) => (
                        <ListItem
                            sx={{ pl: 0, pr: 0 }}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveItem(item.id)}>
                                    <img src="/trash_icon.svg" alt="" />
                                </IconButton>
                            }
                        >
                            <ListItemText
                                sx={{ width: '50%' }}
                                primaryTypographyProps={{ sx: { color: '#151F57', fontSize: '16px', fontWeight: 600 } }}
                                primary={item.title}
                            />
                            <ListItemText
                                sx={{ width: '20%' }}
                                primaryTypographyProps={{ sx: { color: '#7A7B84', fontSize: '16px' } }}
                                primary={`x${item.count}`}
                            />
                            <ListItemText
                                sx={{ width: '20%' }}
                                primaryTypographyProps={{ sx: { color: '#111', fontSize: '16px' } }}
                                primary={t('header.cart.item_price', { price: item.total })}
                            />
                        </ListItem>
                    ))
                )}
            </List>
        </>
    );
};

export const Cart = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [isWarningModalOpen, setWarningModalOpen] = useState(false);

    const { id } = useSelector((state) => state.account.selected);

    const { list, total } = useSelector((state) => state.cart);
    const ref = useRef(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useDidMountEffect(() => {
        if (!open && list.length > 0) {
            ref.current.click();
        }
    }, [list])

    const navigateToCheckout = () => {
        navigate(ROUTES.STORE.CHECKOUT);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseWarningModal = () => {
        setWarningModalOpen(false);
    }

    const handleCheckout = () => {
        if (!Boolean(id)) {
            setWarningModalOpen(true);
            return;
        }
        navigateToCheckout();
    }

    return (
        <>
            <IconButton sx={{ mr: { md: 3, xs: 1 } }} ref={ref} onClick={handleClick}>
                <Badge color="primary" badgeContent={list.length} slotProps={{ badge: { style: { backgroundColor: '#68C8C6' } } }}>
                    <SvgIcon component={BasketIcon} style={{ color: open ? '#ffffff' : '#A1A5BC' }} inheritViewBox />
                </Badge>
            </IconButton>

            <Popover
                id="accounts-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{ '& .MuiPopover-paper': { p: '16px' } }}
            >
                <Box sx={{ maxWidth: '564px', width: '564px' }}>
                    <Typography variant="h3" sx={{ fontSize: '18px' }}>
                        {t('header.cart.title')}
                    </Typography>
                    <CartList list={list} />
                    {list.length === 0 ? null : (
                        <>
                            <Divider />
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems='center' width="100%" marginTop={2}>
                                <Typography variant='subtitle1'>Online order</Typography>
                                <Stack
                                    sx={{ backgroundColor: '#f1f3ff', p: 1, borderRadius: '4px' }}
                                    direction="row"
                                    spacing={2}
                                    justifyContent={'flex-end'}
                                    alignItems="baseline"
                                >
                                    <Typography variant='subtitle2' sx={{ fontWeight: '500' }}>{t('header.cart.total', { price: total })}</Typography>
                                    <Button
                                        onClick={handleCheckout}
                                        variant="contained"
                                        sx={{ backgroundColor: '#151F57', width: '164px', height: '48px', fontSize: '18px' }}
                                    >
                                        {t('header.cart.checkout')}
                                    </Button>
                                </Stack>
                            </Stack>
                        </>
                    )}
                </Box>
            </Popover>
            <SelectClientConfirmationModal isOpen={isWarningModalOpen} onClose={handleCloseWarningModal} text={t('common.warnings.select_account')} />
        </>
    );
};
