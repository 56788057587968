import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import useImage from "hooks/useImage"

const Image = ({ src, errorIcon = null, ...rest }) => {
    const [_, status] = useImage(src);
    if (!src) return <>{errorIcon}</>
    if (status === "loading") return <CircularProgress color="inherit" size={20} />
    if (status === "failed") return <>{errorIcon}</>
    return <Box component="img" src={src} {...rest} />
}

export default Image;