import { initialState } from '../reducers/config';
import { cloneDeep, merge } from 'lodash';
const ConfigInitialState = () => {
    if (localStorage.getItem('config')) {
        const initialStateDeepCopy = JSON.parse(JSON.stringify(initialState));
        const localStore = JSON.parse(localStorage.getItem('config'));
        return {
            config: merge(
                initialStateDeepCopy,
                localStore
            )
        };
    }
    return {};
};


export default ConfigInitialState;