import { useState } from 'react';
import TokenService from 'services/token/Token';

const hasAll =
    (state) =>
        (permissionsKey) => {
            const arrayPermissions = Array.isArray(permissionsKey) ? permissionsKey : permissionsKey.split("|");
            if (state && state.length > 0) {
                return arrayPermissions.every((permission) => state.includes(permission));
            }
            return false;
        };

const hasAny =
    (state) =>
        (permissionsKey) => {
            const arrayPermissions = Array.isArray(permissionsKey) ? permissionsKey : permissionsKey.split("|");
            if (state && state.length > 0) {
                return arrayPermissions.some((permission) => state.includes(permission));
            }
            return false;
        };

const doesNotHaveAll =
    (state) =>
        (permissionsKey) => {
            !hasAll(state)(permissionsKey);
        };

const doesNotHaveAny =
    (state) =>
        (permissionsKey) => {
            !hasAny(state)(permissionsKey);
        };

function usePermissions() {
    const [state] = useState(TokenService.getAuthPermissions());

    return { hasAny: hasAny(state), hasAll: hasAll(state), doesNotHaveAny: doesNotHaveAny(state), doesNotHaveAll: doesNotHaveAll(state) };
}

export default usePermissions;