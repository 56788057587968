// ==============================|| OVERRIDES - CARD ||============================== //

export default function Card(theme) {
    return {
        MuiCard: {
            variants: [
                {
                    props: { color: 'nyanza' },
                    style: {
                        backgroundColor: theme.palette.nyanza.main
                    }
                },
                {
                    props: { color: 'azureish_white' },
                    style: {
                        backgroundColor: theme.palette.azureish_white.main
                    }
                },
                {
                    props: { color: 'old_lace' },
                    style: {
                        backgroundColor: theme.palette.old_lace.main
                    }
                },
                {
                    props: { color: 'misty_rose' },
                    style: {
                        backgroundColor: theme.palette.misty_rose.main
                    }
                }
            ],
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '8px'
                }
            }
        }
    };
}
