// ==============================|| OVERRIDES - TABS ||============================== //

export default function Popover(theme) {
    return {
        MuiPopover: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#fff",
                    padding: "10px"
                }
            }
        }
    };
}
