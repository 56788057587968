import { useEffect, useState } from "react";
import { AccountSelect } from "components/account-select/AccountSelect";
import { useDispatch } from "react-redux";
import { setSelected } from "store/reducers/account";
import ClientsService from "services/clients/Clients";
import useStateAsync from "hooks/useStateAsync";

const AccountSelectContainer = () => {
    const clients = useStateAsync(ClientsService.getClientsList, { clientQueryParameterName: "clientIds", immediate: false });

    useEffect(() => {
        clients.execute({ 'sort.name': "ASC" });
    }, []);

    const dispatch = useDispatch();

    const handleAccountSelectSearch = (value) => {
        // clientIds is set to null to unset from request query
        clients.execute({ title: value, clientIds: null });
    };

    const handleAccountSelect = (value, type) => {
        dispatch(setSelected({ selected: { ...value, multiple: type === 'all' } }));
    };


    const accountsData = clients?.data?.data?.data || [];

    const [defaultSelectedAccount, setDefaultSelectedAccount] = useState(null)

    useEffect(() => {
        if (!accountsData) return;
        if (clients.isInitialRequest && accountsData.length === 1) {
            const defaultAccount = accountsData[0];
            setDefaultSelectedAccount(defaultAccount)
            dispatch(setSelected({ selected: defaultAccount }));
            return;
        }
    }, [clients.isInitialRequest, accountsData])


    return (<AccountSelect
        isInitiallyLoaded={clients.isInitialRequest}
        loading={clients.isLoading}
        data={accountsData}
        defaultSelectedAccount={defaultSelectedAccount}
        optionConfig={{ id: 'id', text: 'title', image: 'image', uuid: 'uuid' }}
        onSearch={handleAccountSelectSearch}
        onSelect={handleAccountSelect}
    />
    );
};


export default AccountSelectContainer;
