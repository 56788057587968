const CartInitialState = () => {
    if (sessionStorage.getItem('cart')) {
        const localstore = JSON.parse(sessionStorage.getItem('cart'));
        return {
            cart: localstore
        };
    }
    return {};
};

export default CartInitialState;