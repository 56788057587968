import { createSlice } from '@reduxjs/toolkit';


// initial state

// initial state
export const initialState = {
    columnsVisibility: {
        onechips: {
            eid: true,
            imsi: true,
            iccid: false,
            bootstrapImsi: false,
            bootstrapIccid: false,
            planName: true,
            deviceGroupName: true,
            deviceName: true,
            clientTitle: false,
            deviceStatus: true,
            euiccStatus: true,
            lastEsimHeartbeat: false,
            privateBootstrapIp: false,
            privateOperationalIp: false,
            publicBootstrapIp: false,
            publicOperationalIp: false,
            totalConsumption: true
        }
    },
    columnsWidth: {
        onechips: {}
    }
};

const config = createSlice({
    name: 'config',
    initialState,
    reducers: {

        setColumnsVisibility(state, action) {
            const { name, list } = action.payload;
            state.columnsVisibility = { ...state.columnsVisibility, [name]: { ...state.columnsVisibility[name], ...list } };
        },
        setColumnsWidth(state, action) {
            const { name, list } = action.payload;
            state.columnsWidth = { ...state.columnsWidth, [name]: { ...state.columnsWidth[name], ...list } };
        }
    }
});

export default config.reducer;

export const { setColumnsVisibility, setColumnsWidth } = config.actions;
