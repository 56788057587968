// ==============================|| OVERRIDES - CHECKBOX ||============================== //

import { styled } from "@mui/material";

const BpIcon = styled('span')(({ theme }) => ({
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    border: '1px solid var(--grey-light-grey, #ECECEC)',
    backgroundColor: '#FFF',
    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.10)',
    padding: 0,
    margin: 0
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: 'rgba(82, 100, 201, 1)',
    '&:before': {
        display: 'block',
        width: '24px',
        height: '24px',
        backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cg clip-path='url(%23clip0_10647_122502)'%3E%3Cpath d='M7.52195 12.9986L4.29677 9.77344L3.19751 10.8727L7.52195 15.1868L16.8034 5.9053L15.7042 4.81641L7.52195 12.9986Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_10647_122502'%3E%3Crect x='0.667236' y='0.667969' width='18.6667' height='18.6667' rx='4' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E\")",
        backgroundPosition: '1px 1px',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: 'rgba(82, 100, 201, 1)',
    },
});

const BpInterminateIcon = styled(BpIcon)({
    backgroundColor: 'rgba(82, 100, 201, 1)',
    '&:before': {
        display: 'block',
        width: '24px',
        height: '24px',
        backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg clip-path='url(%23clip0_17088_14654)'%3E%3Crect x='3' y='8' width='12' height='2' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_17088_14654'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E\")",
        backgroundPosition: '2px 2px',
        backgroundRepeat: 'no-repeat',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: 'rgba(82, 100, 201, 1)',
    },
});


export default function Checkbox(theme) {
    return {
        MuiCheckbox: {
            defaultProps: {
                underline: 'hover',
                icon: <BpIcon />,
                checkedIcon: <BpCheckedIcon />,
                indeterminateIcon: <BpInterminateIcon />
            },
            styleOverrides: {
                root: {
                    color: theme.palette.secondary[300]
                }
            }
        }
    };
}
