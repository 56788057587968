import { Paper, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { SvgIcon } from '@mui/material';
import { ReactComponent as ErrorIcon } from 'assets/images/no_results.svg';
import { Trans, useTranslation } from 'react-i18next';

const NoResults = () => {
    const { t } = useTranslation();
    return (
        <MainCard
            sx={{ flex: 1 }}
            contentSX={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
        >
            <SvgIcon component={ErrorIcon} sx={{ width: 352, height: 148, fill: '#fff' }} inheritViewBox />
            <Typography component={'span'} sx={{ fontSize: 20, mt: 3 }}>
                {t('common.errors.no_results.title')}
            </Typography>
            <Typography align="center" component={'span'} sx={{ fontSize: 16, mt: 2, color: '#888' }}>
                {/*Use Trans component to output html tags specified in translations*/}
                <Trans t={t} i18nKey="common.errors.no_results.text" />
            </Typography>
        </MainCard>
    );
};

export default NoResults;
