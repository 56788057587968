import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, Box, CircularProgress, IconButton, styled, SvgIcon } from '@mui/material';

import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import TokenService from 'services/token/Token';
import AuthService from 'services/auth/Auth';
import { generatePath, useMatch, useNavigate } from 'react-router';
import { ROUTES } from '../../routes/const';
import { clear } from 'store/reducers/cart';
import { useDispatch, useSelector } from 'react-redux';

import ExpandIconButton from 'components/shared/buttons/ExpandIconButton';
import { CaretDownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Image from 'components/image/Image';
import CircularProgressLoader from 'components/shared/loader/CircularProgressLoader';
import { useAvatarContext } from 'contexts/AvatarContext';
import { ReactComponent as AvatarIcon } from 'assets/icons/avatar.svg';


const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    color: '#151F57',
    fontWeight: '500',

    padding: "8px 0px 8px 0px",
    "& .MuiTypography-root": {
        fontSize: '16px',
        fontWeight: 500
    },
    '& a': {
        color: "#000",
        textDecoration: "none"
    }
}));

const IconMenu = () => {
    const dispatch = useDispatch();

    let navigate = useNavigate();
    const user = TokenService.getData();

    const handleLogout = () => {
        dispatch(clear());
        AuthService.logout();
    }

    const handleMyUserClick = () => {
        navigate(generatePath(ROUTES.USER.INDEX));
    }
    return (
        <Box sx={{ width: 250, maxWidth: '100%' }}>
            <MenuList dense={false} disablePadding={true} sx={{ p: 0 }}>
                <Box sx={{ mb: 2 }}>
                    <Typography variant='h5' sx={{ fontSize: "18px" }}>{user?.data?.name}</Typography>
                    <Typography variant='body1' sx={{ mt: 1, color: "#7A7B84" }}>{user?.data?.email}</Typography>
                </Box>
                <Divider />
                <Box sx={{ my: 1 }}>
                    <StyledMenuItem sx={{ px: 0 }} onClick={handleMyUserClick}>
                        <ListItemIcon>
                            <img src="/person_icon.svg" alt="My user" />
                        </ListItemIcon>
                        <ListItemText>My user</ListItemText>
                    </StyledMenuItem>
                    <StyledMenuItem sx={{ px: 0 }} component={Link} to={CONFIG.app.links.header.supportUrl}>
                        <ListItemIcon>
                            <img src="/help_icon.svg" alt="Support" />
                        </ListItemIcon>
                        <ListItemText>Support</ListItemText>
                    </StyledMenuItem>
                </Box>
                <Divider />
                <Box sx={{ mt: 1 }}>
                    <StyledMenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <img src="/logout_icon.svg" alt="Log out" />
                        </ListItemIcon>
                        <ListItemText>Log out</ListItemText>
                    </StyledMenuItem>
                </Box>
            </MenuList>
        </Box>
    );
}

const AvatarImage = () => {
    const { data, isLoading } = useAvatarContext();
    if (isLoading) {
        return <CircularProgress color="inherit" size={20} />
    }
    return <Image
        src={data?.data}
        errorIcon={<SvgIcon component={AvatarIcon} style={{ width: '40px', height: '40px' }} inheritViewBox />}
        alt="Avatar"
        style={{ width: '40px', height: '40px' }}
    />
}


const Profile = () => {


    const userPageMatch = useMatch({ path: ROUTES.USER.INDEX, end: false });

    const [anchor, setAnchor] = useState(null);


    const handleOpenMenu = (event) => {
        setAnchor(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchor(null);
    };

    return <>
        <Box sx={{ backgroundColor: userPageMatch || Boolean(anchor) ? "#5E69A8" : "profileMenuContainer.main", width: "77px", height: "56px", borderRadius: "28px", padding: "8px", display: "flex", justifyContent: "left", alignItems: "center" }}>
            <IconButton onClick={handleOpenMenu} sx={{ width: "100%", display: "flex", justifyContent: "space-around", padding: 0 }}>
                <Avatar>
                    <AvatarImage />
                </Avatar>
                <ExpandIconButton sx={{ height: '15px', width: '15px' }} expand={Boolean(anchor)}>
                    <SvgIcon component={CaretDownOutlined} sx={{ fontSize: '10px', color: Boolean(anchor) ? '#FFFFFF' : '#A1A5BC' }} inheritViewBox></SvgIcon>
                </ExpandIconButton>
            </IconButton>

        </Box>
        <Menu
            sx={{ mt: '45px', "& .MuiPaper-root": { p: 2 } }}
            id="menu-appbar"
            disableScrollLock={true}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchor)}
            onClose={handleCloseMenu}
        >
            <IconMenu />
        </Menu>
    </>
}

export default Profile;
