import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAsyncNew, defaultConfig } from './useAsyncNew';

const defaultSelectStateParams = () => null;

export const useStateAsync = (asyncFunction, config = { selectStateParams: defaultSelectStateParams }) => {

    const { clientQueryParameterName, selectStateParams, ...asyncConfig } = { ...defaultConfig, ...config };

    const clientId = useSelector((state) => {
        const { id: clientId } = state.account.selected;
        return clientId;
    });

    const service = useCallback(async (...args) => {
        const [firstArg = {}, ...restArgs] = args;
        const result = await asyncFunction({ [clientQueryParameterName]: clientId, ...firstArg }, ...restArgs)
        return result;
    }, [asyncFunction, clientId]);

    const result = useAsyncNew(service, { ...asyncConfig, accountChange: clientId });

    return result;
}

export default useStateAsync;