import { useState } from "react";
import TokenService from "services/token/Token";
import usePermissions from "hooks/usePermissions";

const Permission = ({
    hasAll,
    hasAny,
    doesNotHaveAll,
    doesNotHaveAny,
    children,
}) => {
    const methods = usePermissions();
    const [hasPermission, setHasPermission] = useState(null);

    if (hasAll && hasPermission === null) {
        setHasPermission(methods.hasAll(hasAll));
    }

    if (hasAny && hasPermission === null) {
        setHasPermission(methods.hasAny(hasAny));
    }

    if (doesNotHaveAll && hasPermission === null) {
        setHasPermission(methods.doesNotHaveAll(doesNotHaveAll));
    }

    if (doesNotHaveAny && hasPermission === null) {
        setHasPermission(methods.doesNotHaveAny(doesNotHaveAny));
    }

    if (typeof children === 'function') {
        return children(hasPermission);
    }

    if (hasPermission || (!hasAll && !hasAny && !doesNotHaveAll && !doesNotHaveAny)) {
        return <>{children}</>;
    }

    return null;
}

export default Permission;