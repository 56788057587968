const TableColumnsVisibilityStoreMiddleware = ({ getState }) => {
    return (next) => (action) => {
        const result = next(action);
        if (action.type.startsWith('config')) {
            const { config } = getState();
            localStorage.setItem('config', JSON.stringify(config))
        }
        return result;
    };
};

export default TableColumnsVisibilityStoreMiddleware;