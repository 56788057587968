const AccountStoreMiddleware = ({ getState }) => {
    return (next) => (action) => {
        const result = next(action);
        if (action.type.startsWith('account')) {
            localStorage.setItem(
                'account',
                `{
              "selected": ${JSON.stringify(getState().account.selected)}
            }`
            );
        }
        return result;
    };
};

export default AccountStoreMiddleware;