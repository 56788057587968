import usePrevious from 'hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/const';
import { clear } from 'store/reducers/cart';


export function RequireClearCartOnAccountChange({ children }) {
    const { id = null } = useSelector((state) => state.account.selected);
    const prevId = usePrevious(id);

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const { list } = useSelector((state) => state.cart);

    const dispatch = useDispatch();

    const hasAccountChanged = (prevId !== id && prevId !== null);

    if (hasAccountChanged && list.length > 0) {
        dispatch(clear());
        navigate(pathname, { replace: true })
        return;
    }

    return children;
}
