export const ROUTES = {
    LOG_IN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    DASHBOARD: '/',
    FEED: '/feed',
    USER: {
        INDEX: '/user',
        API: '/user/api',
        INVOICE_HISTORY: '/user/invoice-history',
    },
    STORE: {
        INDEX: '/store',
        CHECKOUT: '/store/checkout',
        CHECKOUT_ERROR: '/store/checkout/error',
        CHECKOUT_SUCCESS: '/store/checkout/success',
        ORDER_HISTORY: '/store/order-history',
        PAYMENT_HISTORY: '/store/payment-history',
    },
    NETWORK_APPS: '/network-apps',
    ONECHIPS: '/onechips',
    ONECHIP_DETAILS: {
        INDEX: '/onechips/:eid',
        PERFORMANCE: '/onechips/:eid/performance',
        REPORTS: '/onechips/:eid/reports',
        FEED: '/onechips/:eid/feed',
        MEERKAT: '/onechips/:eid/meerkat',
        SEND_SMS: '/onechips/:eid/sms',
    },
    MARKETPLACE: '/marketplace',
    ADD_ONS: {
        INDEX: '/add-ons',
        ADD_ON: '/add-ons/products',
        ADD_ON_ORDER_SUCCESS: '/add-ons/products/success'
    },
    SUPPORT: '/support',
    NOT_FOUND: '*'
};
