import { Navigate } from 'react-router-dom';
import { ROUTES } from 'routes/const';
import TokenService from 'services/token/Token';

export function RequireAuthentication({ children, isAuthenticated = true, redirectTo = ROUTES.LOG_IN }) {
    const authToken = TokenService.getData();
    const canAccess = Boolean(authToken) === isAuthenticated;

    if (!canAccess) {
        return <Navigate to={redirectTo} replace />;
    }

    return children;
}
