// ==============================|| OVERRIDES - BUTTON ||============================== //
export default function Button(theme) {
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */ }
    const disabledStyle = {
        '&.Mui-disabled': {
            backgroundColor: theme.palette.grey[200]
        }
    };

    return {
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            variants: [
                {
                    props: { variant: 'contained', color: 'white' },
                    style: {
                        backgroundColor: `#fff`,
                        color: '#151F57'
                    }
                },
                {
                    props: { variant: 'outlined', color: 'white' },
                    style: {
                        backgroundColor: 'transparent',
                        color: '#fff',
                        border: '1px solid #fff'
                    }
                }
            ],
            styleOverrides: {
                root: ({ ownerState }) => ({
                    fontWeight: 600,
                    letterSpacing: '0.02em',
                    fontSize: '16px',
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'primary' && {
                        '&:hover': {
                            backgroundColor: '#213082'
                        },
                        '&:active': {
                            backgroundColor: '#00072E'
                        },
                        '&.Mui-disabled': {
                            backgroundColor: '#73799A',
                            color: '#ffffff'
                        },
                        '& .MuiLoadingButton-loadingIndicator .MuiCircularProgress-root': {
                            color: '#FFFFFF'
                        }
                    }),

                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'secondary' && {
                        '&:hover': {
                            backgroundColor: '#50B1AF'
                        },
                        '&:active': {
                            backgroundColor: '#4A8E8D'
                        },
                        '&.Mui-disabled': {
                            backgroundColor: '#D0EEED'
                        },
                        '& .MuiLoadingButton-loadingIndicator .MuiCircularProgress-root': {
                            color: '#FFFFFF'
                        }
                    }),

                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'gold' && {
                        '&:hover': {
                            backgroundColor: '#FFAC5F'
                        },
                        '&:active': {
                            backgroundColor: '#EC7C15'
                        },
                        '&.Mui-disabled': {
                            backgroundColor: '#FFAC5F'
                        },
                        '& .MuiLoadingButton-loadingIndicator .MuiCircularProgress-root': {
                            color: '#ffffff'
                        }
                    }),
                    ...(ownerState.variant === 'outlined' &&
                        ownerState.color === 'primary' && {
                        '&:hover': {
                            backgroundColor: '#F3F5FF',
                            borderColor: '#213082',
                            colot: '#213082'
                        },
                        '&:active': {
                            backgroundColor: '#EAEDFF',
                            borderColor: '#00072E',
                            color: '#00072E'
                        },
                        '&.Mui-disabled': {
                            backgroundColor: 'transparent',
                            borderColor: '#73799A',
                            color: '#73799A'
                        },
                        '& .MuiLoadingButton-loadingIndicator .MuiCircularProgress-root': {
                            color: '#151F57'
                        }
                    }),

                    ...(ownerState.variant === 'text' &&
                        ownerState.color === 'primary' && {
                        color: '#151F57',
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            textDecoration: 'underline'
                        },
                        '&:active': {
                            backgroundColor: '#00072E'
                        },
                        '&.Mui-disabled': {
                            color: '#73799A'
                        }
                    })
                }),
                text: {
                    backgroundColor: 'transparent !important'
                },
                outlined: {
                    borderWidth: '2px !important'
                }
            }
        }
    };
}
