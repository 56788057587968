import React, { useState, useEffect, useCallback, useRef } from 'react';

const makeStatusParams = (status) => {
    const initialStatus = {
        isSuccess: false,
        isError: false,
        isLoading: false
    };
    if (status === 'pending') {
        return {
            ...initialStatus,
            isLoading: true
        };
    }
    if (status === 'error') {
        return {
            ...initialStatus,
            isError: true
        };
    }
    if (status === 'success') {
        return {
            ...initialStatus,
            isSuccess: true
        };
    }
    return initialStatus;
};
export const defaultConfig = {
    clientQueryParameterName: 'clientId',
    immediate: true,
    accountChange: false,
    defaultState: {
        status: 'idle',
        value: null,
        error: null,
        isInitialRequest: false,
    }
};

export const useAsyncNew = (asyncFunction, config = defaultConfig) => {
    const { immediate, accountChange, defaultState } = {
        ...defaultConfig,
        ...config,
        defaultState: { ...defaultConfig.defaultState, ...config.defaultState }
    };
    const [status, setStatus] = useState(defaultState.status);
    const [value, setValue] = useState(defaultState.value);
    const [error, setError] = useState(defaultState.error);

    const requestsCount = useRef(immediate ? 1 : 0);
    let isAccountChanged = useRef(false);
    const lastRequest = useRef(Promise.reject);

    useEffect(() => {
        isAccountChanged.current = true;
        return () => {
            isAccountChanged.current = false;
        };
    }, [accountChange]);

    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback(
        (...args) => {
            requestsCount.current++;
            setStatus('pending');
            setValue(null);
            setError(null);
            lastRequest.current = () => execute(...args);
            return asyncFunction(...args)
                .then((response) => {
                    isAccountChanged.current = false;
                    setValue(response);
                    setStatus('success');
                })
                .catch((error) => {
                    requestsCount.current--;
                    isAccountChanged.current = false;

                    setError(error);
                    setStatus('error');

                });
        },
        [asyncFunction]
    );

    const reset = (props = {}) => {
        const stateProps = { ...defaultState, ...props };
        setStatus(stateProps.status);
        setValue(stateProps.value);
        setError(stateProps.error);
    };
    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);
    return {
        reset,
        execute,
        data: value,
        status,
        error,
        ...makeStatusParams(status),
        isInitialRequest: requestsCount.current === 1,
        isAccountChanged: isAccountChanged.current,
        lastRequest: lastRequest.current,
    };
};
