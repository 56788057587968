// ==============================|| OVERRIDES - TABS ||============================== //

export default function Paper() {
    return {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff"
                }
            }
        }
    };
}
