import MainLayout from 'layout/MainLayout';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import Unknown from './Unknown';

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => {
    const location = useLocation();
    const errorLocation = useRef(location.pathname);
    useEffect(() => {
        if (location.pathname !== errorLocation.current) {
            resetErrorBoundary();
        }
    }, [location.pathname]);
    return (
        <MainLayout>
            <Unknown></Unknown>
        </MainLayout>
    );
};

export default ErrorBoundaryFallback;
