import { createSlice, current } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { countNonEmptyFilters } from 'utils/functions';
import { createSelector } from "@reduxjs/toolkit";

// initial state
export const initialState = {
    count: 0,
    refresh: true,
    filters: {
        list: {
            filteredEids: [],
            operationalImsi: '',
            operationalIccid: '',
            bootstrapImsi: '',
            bootstrapIccid: '',
            planIdList: [],
            deviceGroupName: '',
            deviceName: '',
            clientTitle: '',
            deviceStatusList: [],
            operationStatusList: []
        },
        limit: 20,
        offset: 0,
        page: 1,
        sort: {}
    }
};


const manualInstallOnechips = createSlice({
    name: 'manualInstallOnechips',
    initialState,
    reducers: {
        setFilters(state, action) {
            const { list = {}, refresh = true, ...restFilters } = action.payload;
            state.filters = { ...state.filters, list: { ...state.filters.list, ...list }, ...restFilters };
            state.count = countNonEmptyFilters(state.filters.list, initialState.count);
            state.refresh = refresh;
        },

        clearFilters(state, action) {
            state.filters = cloneDeep(initialState.filters);
            state.count = initialState.count;
        }
    }
});

export default manualInstallOnechips.reducer;

export const { setFilters, clearFilters } = manualInstallOnechips.actions;


const getSlice = state => state.manualInstallOnechips.filters;

export const getQueryFilters = createSelector(getSlice, filtersObj => {
    const { list: filters, ...restFilters } = filtersObj;
    const originalFilters = filters;
    const filtersEntries = Object.entries(filters).map(([filterName, filterValue]) => {
        if (Array.isArray(filterValue)) {
            const values = (filterValue.length > 0 && typeof filterValue[0] === 'object') ? filterValue.flatMap(item => item.id) : filterValue
            return [filterName, values];
        }
        return [filterName, filterValue];
    });
    return {
        list: Object.fromEntries(filtersEntries),
        originalList: originalFilters,
        ...restFilters
    }
})
