import { createSlice, current } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { countNonEmptyFilters } from 'utils/functions';

// initial state
export const initialState = {
    count: 0,
    refresh: true,
    filters: {
        list: {
            filteredEids: [],
            operationalImsi: '',
            operationalIccid: '',
            bootstrapImsi: '',
            bootstrapIccid: '',
            planIdList: [],
            deviceGroupName: '',
            deviceName: '',
            clientTitle: '',
            meerkatIp: '',
            deviceStatusList: [],
            operationStatusList: []
        },
        limit: 20,
        offset: 0,
        page: 1,
        sort: {}
    }
};


const onechips = createSlice({
    name: 'onechips',
    initialState,
    reducers: {
        setFilters(state, action) {
            const { list = {}, refresh = true, ...restFilters } = action.payload;
            state.filters = { ...state.filters, list: { ...state.filters.list, ...list }, ...restFilters };
            state.count = countNonEmptyFilters(state.filters.list, initialState.count);
            state.refresh = refresh;
        },

        clearFilters(state, action) {
            state.filters = cloneDeep(initialState.filters);
            state.count = initialState.count;
        }
    }
});

export default onechips.reducer;

export const { setFilters, clearFilters } = onechips.actions;
