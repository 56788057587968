import PropTypes from 'prop-types';

// material-ui
import { Box, Divider, Grid } from '@mui/material';

// project import
import AuthCard from './AuthCard';


import Footer from 'components/footer';

// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper = ({ children }) => (
    <Box sx={{ minHeight: '100vh' }}>
        <Grid
            container
            direction="column"
            justifyContent="flex-end"
            sx={{
                minHeight: '100vh'
            }}
        >
            <Grid item xs={12} sx={{ ml: 8, mt: 3 }}>
                <Box component="img" src={CONFIG.app.links.auth_logo_url} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, maxHeight: '45px' }} />
            </Grid>
            <Grid item xs={12}>
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ minHeight: { xs: 'calc(100vh - 268px)', md: 'calc(100vh - 250px)' } }}
                >
                    <Grid item>
                        <AuthCard>{children}</AuthCard>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>

                <Box sx={{ height: "134px", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", mt: 6, boxSizing: "content-box" }}>
                    <Divider sx={{ color: '#ececec' }} />
                    <Box sx={{ width: "100%", display: "flex", alignItems: "end", justifyContent: "center", marginBottom: "40px", marginTop: "24px" }}>
                        <Footer />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    </Box>
);

AuthWrapper.propTypes = {
    children: PropTypes.node
};

export default AuthWrapper;
