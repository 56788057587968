// ==============================|| OVERRIDES - LINER PROGRESS ||============================== //

export default function Tooltip(theme) {
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: theme.palette.common.white,
                    color: "#111",
                    border: "1px solid #D9D9D9",
                    padding: "16px",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "17px",
                    boxShadow: theme.shadows[5],
                },
                arrow: {
                    "&:before": {
                        border: "1px solid #D9D9D9",
                    },
                    fontSize: "21px",
                    color: theme.palette.common.white,
                }
            }
        }
    };
}
