import SelectClientConfirmationModal from "components/modals/SelectClientConfirmationModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NoResults from "components/Error/NoResults";
import { useTranslation } from "react-i18next";


export const RequireAccountSelection = ({ children }) => {
    const { id = null, text } = useSelector((state) => state.account.selected);

    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!Number.isInteger(id)) {
            setShowModal(true);
        }
    }, [id]);

    const handleOnClose = () => {
        setShowModal(false);
    }

    if (id) {
        return children;
    }
    return <>
        <NoResults />
        <SelectClientConfirmationModal isOpen={showModal} onClose={handleOnClose} text={t('common.warnings.select_account')} />
    </>
}
