// ==============================|| OVERRIDES - TABS ||============================== //

export default function AppBar(theme) {
    return {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: `${theme.palette.menuMain.main}`,
                    color: `${theme.palette.menuMain.contrastText} !important`
                }
            }
        }
    };
}
