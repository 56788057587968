import PropTypes from 'prop-types';
import { useMemo } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// project import
import DefaultTheme from './default';
import ComponentsOverrides from './default/components';
import SimetryTheme from './simetry';
import RealcomTheme from './realcom';
import VastTheme from './vast';
import SkywayTheme from './skyway';
import FidesTheme from './fides';

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({ children }) {

    let themes = DefaultTheme;
    switch (CONFIG.app.theme) {
        case "simetry":
            themes = SimetryTheme;
            break;
        case "realcom":
            themes = RealcomTheme;
            break;
        case "vast":
            themes = VastTheme;
            break;
        case "skyway":
            themes = SkywayTheme;
            break;
        case "fides":
            themes = FidesTheme;
            break;
        case "default":
            themes = DefaultTheme;
            break;
        default: break;
    }
    themes.components = { ...ComponentsOverrides(DefaultTheme), ...themes.components };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

ThemeCustomization.propTypes = {
    children: PropTypes.node
};
