namespace Permissions {
    export enum StoreSection {
        ViewStore = 'cp_ord_menu_v'
    }

    export enum DashboardSection {
        ViewDashboard = 'cp_dashboard_v'
    }

    //TODO change permissions for network apps
    export enum MarketplaceSection {
        ViewNetworkApps = 'cp_mp_menu_v',
        ChangePlanAction = 'cp_change_plan'
    }

    export enum AddonsSection {
        ViewAddons = 'cp_aon_menu_v'
    }

    export enum OnechipCardsSection {
        ViewOnechipCards = 'cp_oc_menu_v',
        PerformActivateAction = 'cp_oci_dn_u',
        PerformDeactivateAction = 'cp_oci_dn_u',
        PerformDeviceGroupUpdateAction = 'cp_oci_dn_u',
        PerformBulkUploadAction = 'cp_oci_dn_u',
        PerformMeerkatAction = 'cp_oci_dn_u'
    }

    export enum MeerkatSection {
        ViewMeerkat = 'cp_meerkat_menu'
    }

    export enum UserSection {
        ViewInvoiceHistory = 'cp_invoice_w_v',
        ViewPaymentMethods = 'cp_oci_dn_u'
    }
}

export default Permissions;
