// ==============================|| OVERRIDES - Pagination ||============================== //

export default function Pagination(theme) {
    return {
        MuiPagination: {
            styleOverrides: {
                root: {
                    '& ul .Mui-selected': {
                        backgroundColor: theme.palette.purple.main
                    }
                }
            }
        }
    };
}
