import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';

const CircularProgressLoader = ({ value, showLabel = true, ...props }) => {
    return (
        <Stack alignItems={'center'} justifyContent={'center'}>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" value={value} {...props} />
                {showLabel ? (
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography sx={{ fontSize: '16px' }} variant="caption" component="div" color="text.secondary">
                            {`${value}%`}
                        </Typography>
                    </Box>
                ) : null}
            </Box>
            {showLabel && (
                <Typography sx={{ fontSize: '16px', mt: 1 }} variant="caption" component="div" color="text.secondary">
                    Loading...
                </Typography>
            )}
        </Stack>
    );
};

CircularProgressLoader.propTypes = {
    value: PropTypes.number.isRequired,
    showLabel: PropTypes.bool
};

export default CircularProgressLoader;
