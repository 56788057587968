import { forwardRef, useCallback } from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CardContent, Stack, styled } from '@mui/material';
import SuccessIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded';
import { ReactComponent as WarningIcon } from 'assets/images/alert.svg';
import { SvgIcon } from '@mui/material';

const IconVariants = {
    success: SuccessIcon,
    error: ErrorIcon,
    warning: WarningIcon
};

const TitleVariants = {
    success: 'Success',
    error: 'Error',
    warning: 'Warning'
};

const StyledCard = styled(Card)(() => ({
    '&': {
        width: '100%'
    },
    '& .title': {
        width: '100%'
    },
    '& > *': {
        color: '#ffffff'
    },
    '&.notistack-MuiContent-success': {
        backgroundColor: 'rgba(150, 210, 123, 0.8)'
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: 'rgba(255, 72, 72, 0.8);'
    },
    '&.notistack-MuiContent-warning': {
        backgroundColor: 'rgba(255, 190, 45, 0.8)'
    },
    '& .MuiCardContent-root': {
        padding: '8px 16px 8px 16px !important'
    }
}));

const Snackbar = forwardRef(({ id, variant = 'success', ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback((event) => {
        event.stopPropagation();
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    const Icon = IconVariants[variant] ?? null;
    const title = TitleVariants[variant] ?? null;
    return (
        <SnackbarContent ref={ref} style={{ minWidth: '377px', right: 0 }} className="aababa">
            <StyledCard className={`notistack-MuiContent-${variant}`}>
                <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                        {Icon && <SvgIcon component={Icon} sx={{ width: 32, height: 32, mt: 0.5, color: '#ffffff' }} inheritViewBox />}
                        <Stack spacing={1}>
                            {title && <Typography variant="subtitle1">{title}</Typography>}
                            <Typography sx={{ fontWeight: 400 }} variant="subtitle2">{props.message}</Typography>
                        </Stack>
                    </Stack>
                    <IconButton size="small" onClick={handleDismiss}>
                        <CloseIcon sx={{ color: '#ffffff' }} fontSize="small" />
                    </IconButton>
                </CardContent>
            </StyledCard>
        </SnackbarContent>
    );
});

Snackbar.displayName = 'CustomSnackbar';

export default Snackbar;
