import { Link } from 'react-router-dom';

// material-ui
import { Box, Grid, Stack, Typography } from '@mui/material';

// project import
import AuthLogin from './auth-forms/AuthLogin';
import AuthWrapper from './AuthWrapper';
import { useTranslation } from 'react-i18next';

// ================================|| LOGIN ||================================ //

const Login = () => {
    const { t } = useTranslation();
    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                        <Typography variant="h3">{t("views.login.title")}</Typography>
                        <Stack direction="row" spacing={2}>
                            <Typography variant='body1' color="text.secondary">{t("views.login.no_account")}</Typography>
                            <Typography sx={{ color: "#151F57", fontWeight: 600, textDecoration: "none" }} component={Link} to={`mailto:${CONFIG.app.links.supportEmail}`}>{t("views.login.contact_us")}</Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <AuthLogin />
                </Grid>
            </Grid >
        </AuthWrapper >
    )
}

export default Login;
