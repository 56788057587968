import { Box, Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTES } from "routes/const";
import MainRoutes from "routes/MainRoutes";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const Breadcrumbs = () => {
    let breadcrumbs = useBreadcrumbs([MainRoutes], { excludePaths: ['/'] });
    breadcrumbs = breadcrumbs.slice(0, -1);
    return (
        <>
            <Box>
                {breadcrumbs.length === 0 ? null :
                    <>
                        {breadcrumbs.map(({ match, breadcrumb }) => (
                            <Button component={Link} key={match.pathname} startIcon={<ArrowBackIosRoundedIcon />} to={match.pathname} style={{ fontSize: '18px', fontWeight: 500, textDecoration: 'none' }}>
                                {breadcrumb}
                            </Button>
                        ))}
                    </>
                }
            </Box>
        </>
    );
};

export default Breadcrumbs;