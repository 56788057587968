const AccountInitialState = () => {
    if (localStorage.getItem('account')) {
        const localstore = JSON.parse(localStorage.getItem('account'));
        return {
            account: localstore
        };
    }
    return {};
};

export default AccountInitialState;