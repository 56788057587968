import { useMemo } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

import { Cart } from 'components/header/Cart';
import MoreEsims from 'components/header/MoreEsims';
import Profile from 'components/header/Profile';
import { useTranslation } from 'react-i18next';
import { DesktopMenu, MobileMenu } from 'components/header/Menu';
import { ROUTES } from 'routes/const';
import { Notifications } from 'components/header/Notifications';
import Permission from 'components/permission';
import Permissions from 'const/Permissions';
import usePermissions from 'hooks/usePermissions';


const Header = () => {
    const { t } = useTranslation();

    const permissions = usePermissions();
    const menu = useMemo(
        () => [
            {
                title: t('header.nav_menu.dashboard'),
                url: ROUTES.DASHBOARD,
                show: true
            },
            {
                title: t('header.nav_menu.onechip'),
                url: ROUTES.ONECHIPS,
                show: permissions.hasAll(Permissions.OnechipCardsSection.ViewOnechipCards)
            },
            {
                title: t('header.nav_menu.networkapps'),
                url: ROUTES.NETWORK_APPS,
                show: permissions.hasAll(Permissions.MarketplaceSection.ViewNetworkApps)
            },
            {
                title: t('header.nav_menu.add_ons'),
                url: ROUTES.ADD_ONS.INDEX,
                show: permissions.hasAll(Permissions.AddonsSection.ViewAddons)
            },
        ].filter(item => item.show),
        []
    );

    return (
        <AppBar position="fixed">
            <Container style={{ height: '80px', minWidth: '100%', width: '100%' }}>
                <Toolbar disableGutters sx={{ height: '80px', maxWidth: 'xl', margin: 'auto' }}>
                    <Box component="img" src={CONFIG.app.links.logo_url} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, maxHeight: '45px' }} />

                    <MobileMenu menu={menu} />
                    <Box component="img" src={CONFIG.app.links.logo_url} sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, width: '105px' }} />
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}></Box>
                    <DesktopMenu menu={menu} />


                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>
                    <Notifications />

                    <Permission hasAny={Permissions.StoreSection.ViewStore}>
                        <Cart />
                        <MoreEsims />
                    </Permission>

                    <Box sx={{ flexGrow: 0 }}>
                        <Profile />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default Header;
