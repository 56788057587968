import { createSlice, current } from '@reduxjs/toolkit';

const calcSum = (list) => {
    return list.reduce((acc, cur) => acc + Number(cur.total), 0);
}

// initial state
const initialState = {
    list: [],
    total: 0
};

const cart = createSlice({
    name: 'cart',
    initialState,
    reducers: {

        set(state, action) {
            const { id, count, price, title, sku, type } = action.payload;
            const item = {
                id,
                count,
                price,
                title,
                total: parseInt(count) * parseFloat(price),
                sku,
                unitId: id,
                type
            }
            state.list = [...state.list.filter(item => item.id !== id), item];
            state.total = calcSum(state.list)
        },

        remove(state, action) {
            const { id } = action.payload;
            state.list = state.list.filter((item) => item.id !== id);
            state.total = calcSum(state.list);
        },

        clear(state, action) {
            state.list = [];
            state.total = 0;
        }
    }
});

export default cart.reducer;

export const { set, remove, clear } = cart.actions;
