const Palette = () => {

    const contrastText = '#fff';

    const paletteColor = {
        menuMain: {
            main: '#66308C',
            contrastText: '#FFFFFF',
            hoverText: '#FFFFFF'
        },
        simCard: {
            main: '#FFFFFF',
            contrastText
        },
        profileMenuContainer: {
            main: 'transparent',
            contrastText
        }
    };

    return {
        ...paletteColor,
    }
}
export default Palette;
