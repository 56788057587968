import { getWithExpiry, setWithExpiry } from "utils/helpers/LocalStorage";

const authSessionName = 'user';
const storage = localStorage;

const getRefreshToken = () => {
    const data = getData();
    return data?.refreshToken;
};

const getAccessToken = () => {
    const data = getData();
    return data?.token;
};

const getAuthPermissions = () => {
    const data = getData();
    return data?.data?.permissions;
}

const getData = () => {
    return getWithExpiry(authSessionName);
};

const setData = (data) => {
    const ttl = 1000 * 60 * 60 * 24; // one hour
    setWithExpiry(authSessionName, data, ttl);
};

const removeData = () => {
    storage.removeItem(authSessionName);
};

const clear = () => {
    storage.clear();
}

const TokenService = {
    getAccessToken,
    getRefreshToken,
    getAuthPermissions,
    setData,
    getData,
    removeData,
    clear
};

export default TokenService;