import { useEffect, useRef } from 'react';


const useTimeout = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // combining the setInterval and 
    //clearInterval methods based on delay.
    useEffect(() => {

        if (delay === null) {
            return;
        }
        let id = setTimeout(() => savedCallback.current(), delay);
        return () => clearTimeout(id);

    }, [delay]);
}

export default useTimeout;