import api from '../api';

const getOnechipsList = async (params) => {
    const { data } = await api.get(`api/onechip`, {
        params
    });
    return data;
};

const getOnechipsSearchList = async (params) => {
    const { data } = await api.get(`api/onechip/search`, {
        params
    });
    return data;
};

const getOnechipByEid = async (eid, params) => {
    const { data } = await api.get(`api/onechip/${eid}`, {
        params
    });
    return data;
};

const isOnechipExists = async (params, eid) => {
    const { data } = await api.get(`api/onechip/exists/${eid}`, { params });
    return data;
};

const getOnechipInfo = async (eid) => {
    const { data } = await api.get(`api/onechip/info/${eid}`);
    return data?.data?.data;
};

const getOnechipProfiles = async (eid) => {
    const { data } = await api.get(`api/onechip/profiles/eid/${eid}`);
    return data?.data;
};


const changeDeviceGroupNameAndDeviceName = async (params) => {
    const { data } = await api.post(`api/onechip/change-device-group-name-device-name`, {}, { params });
    return data;
};

const sendSms = async (params) => {
    const { data } = await api.post(`api/customer-send-sms`, {}, { params });
    return data;
};

const activateOneChip = async (eid) => {
    const { data } = await api.patch(`api/onechip/activate/${eid}`, {});
    return data;
};

const enableOneChip = async (eid) => {
    const { data } = await api.patch(`api/onechip/enable/${eid}`, {});
    return data;
};

const disableOneChip = async (eid) => {
    const { data } = await api.patch(`api/onechip/disable/${eid}`, {});
    return data;
};

const activateOneChips = async (params) => {
    const { data } = await api.patch(`api/onechips/activate`, {}, { params });
    return data;
};

const disableOneChips = async (params) => {
    const { data } = await api.patch(`api/onechips/disable`, {}, { params });
    return data;
};

const enableOneChips = async (params) => {
    const { data } = await api.patch(`api/onechips/enable`, {}, { params });
    return data;
};

const updateDeviceGroupNames = async (params) => {
    const { data } = await api.patch(`api/onechips/change-group-name`, {}, { params });
    return data;
};

const addDataCaps = async (params, body) => {
    const { data } = await api.patch(`api/datacaps/add-data-cap`, body, { params });
    return data;
};

const removeDataCaps = async (params) => {
    const { data } = await api.patch(`api/datacaps/remove-data-cap`, {}, { params });
    return data;
};

const activationUpload = async (body) => {
    const { data } = await api.post(`api/onechips/activate-upload`, body, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
    return data;
};

const bulkUpload = async (body) => {
    const { data } = await api.post(`api/onechips/bulk-upload`, body, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
    return data;
};

const enablePublicVpn = async (body) => {
    const { data } = await api.post(`api/meerkat-vpn-operations/enable-public-vpn`, body);
    return data;
}

const meerkatIpsOperation = async (params, type) => {
    const { clientId, selectedEids = null, enableMeerkat = false } = params;
    const paramsConfig = {
        ASSIGN_PUBLIC_OPERATIONAL: {
            clientId,
            selectedEids,
            meerkatVpnRegion: 'NORTH_AMERICA',
            meerkatPublic: true,
            meerkatVpnEnabled: true
        },
        ASSIGN_PUBLIC_BOOTSTRAP: {
            clientId,
            selectedEids,
            meerkatVpnRegion: 'EUROPE',
            meerkatPublic: true,
            meerkatVpnEnabled: true
        },
        ASSIGN_PRIVATE_OPERATIONAL: {
            clientId,
            selectedEids,
            meerkatVpnRegion: 'NORTH_AMERICA',
            meerkatPublic: false,
            meerkatVpnEnabled: true
        },
        ASSIGN_PRIVATE_BOOTSTRAP: {
            clientId,
            selectedEids,
            meerkatVpnRegion: 'EUROPE',
            meerkatPublic: false,
            meerkatVpnEnabled: true
        },
        REMOVE_PUBLIC_OPERATIONAL: {
            clientId,
            selectedEids,
            meerkatVpnRegion: 'NORTH_AMERICA',
            meerkatPublic: false,
            meerkatVpnEnabled: false
        },
        REMOVE_PUBLIC_BOOTSTRAP: {
            clientId,
            selectedEids,
            meerkatVpnRegion: 'EUROPE',
            meerkatPublic: false,
            meerkatVpnEnabled: false
        }
    }
    const withDefaultParams = { meerkatData: true, ...paramsConfig[type] };

    if (enableMeerkat) {
        await enablePublicVpn({ clientId });
    }

    const { data } = await api.post(`api/esims/change-meerkat-vpn-enabled-filter`, {}, { params: withDefaultParams });
    return data;
}

const assignMeerkatPrivateIps = async (params) => {
    const withDefaultParams = { meerkatData: true, meerkatPublic: false, meerkatVpnEnabled: true, ...params };
    const { data } = await api.post(`api/esims/change-meerkat-vpn-enabled-filter`, {}, { params: withDefaultParams });
    return data;
};

const assignMeerkatPublicIps = async (params) => {
    const withDefaultParams = { meerkatData: true, meerkatPublic: true, meerkatVpnEnabled: true, ...params };
    const { data } = await api.post(`api/esims/change-meerkat-vpn-enabled-filter`, {}, { params: withDefaultParams });
    return data;
};

const removeMeerkatEuropePublicIps = async (params) => {
    const withDefaultParams = { meerkatData: true, meerkatPublic: false, meerkatVpnEnabled: false, meerkatVpnRegion: 'EUROPE', ...params };
    const { data } = await api.post(`api/esims/change-meerkat-vpn-enabled-filter`, {}, { params: withDefaultParams });
    return data;
};

const removeMeerkatNorthAmericaPublicIps = async (params) => {
    const withDefaultParams = { meerkatData: true, meerkatPublic: false, meerkatVpnEnabled: false, meerkatVpnRegion: 'NORTH_AMERICA', ...params };
    const { data } = await api.post(`api/esims/change-meerkat-vpn-enabled-filter`, {}, { params: withDefaultParams });
    return data;
};

const downloadOneChips = async (params) => {
    const { data } = await api.get(`api/esim/download`, { params });
    return data;
};

const downloadPlanUsage = async (params) => {
    const { data } = await api.post(`api/esims/download-esim-plan-usage`, {}, { params });
    return data;
};

const downloadNetworkHistory = async (params) => {
    const { data } = await api.post(`api/esims/download-esim-usagedata`, {}, { params });
    return data;
};

const restartChangePlan = async (params) => {
    const { data } = await api.post(`api/onechips/restart-change-plan-filter`, {}, { params });
    return data;
}

const terminateChangePlan = async (params) => {
    const { data } = await api.post(`api/onechips/terminate-change-plan-filter`, {}, { params });
    return data;
}

const planChange = async (params) => {
    const { data } = await api.post(`api/onechip/change-plan/filter`, {}, { params });
    return data;
}

const planChangeUpload = async (body, params) => {
    const { data } = await api.post(`api/onechip/change-plan/upload`, body, { params });
    return data;
}

const heartbeatPolling = async (params) => {
    const { data } = await api.get(`api/onechips/polling`, { params });
    return data;
}

const OnechipsService = {
    sendSms,
    getOnechipsList,
    getOnechipsSearchList,
    getOnechipByEid,
    getOnechipInfo,
    getOnechipProfiles,
    activateOneChip,
    enableOneChip,
    disableOneChip,
    activateOneChips,
    disableOneChips,
    enableOneChips,
    updateDeviceGroupNames,
    addDataCaps,
    removeDataCaps,
    activationUpload,
    bulkUpload,
    assignMeerkatPublicIps,
    assignMeerkatPrivateIps,
    removeMeerkatEuropePublicIps,
    removeMeerkatNorthAmericaPublicIps,
    downloadOneChips,
    downloadPlanUsage,
    downloadNetworkHistory,
    restartChangePlan,
    terminateChangePlan,
    heartbeatPolling,
    changeDeviceGroupNameAndDeviceName,
    meerkatIpsOperation,
    planChange,
    planChangeUpload,
    isOnechipExists,
    enablePublicVpn
};

export default OnechipsService;
