import api from "../api";

const getClientsList = async (params) => {
  const { data } = await api.get(`api/clients`, {
    params
  });
  return data;
};


const getClientsAddress = async (clientIdParams) => {
  const params ={
    id : clientIdParams.clientId
  }
  const { data } = await api.get(`api/clients/${clientIdParams.clientId}`, {params});
  return data;
};



const getClientInfoByEmail = async (email) => {
  const params = { email: email };

  const { data } = await api.get(`api/address/billing`);
  return data;
};
const ClientsService = {
  getClientsList,
  getClientInfoByEmail,
  getClientsAddress
};

export default ClientsService;
