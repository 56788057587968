import { createTheme } from '@mui/material/styles';
import { merge } from 'lodash';
import ComponentsOverrides from './components';

import DefaultTheme from '../default';

import Palette from './palette';

const theme = createTheme(DefaultTheme, {
    palette: Palette(),
    components: ComponentsOverrides(DefaultTheme)
})

export default theme;