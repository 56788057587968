// material-ui
import { createTheme } from '@mui/material/styles';

// ==============================|| DEFAULT THEME - CUSTOM SHADOWS  ||============================== //
const theme = createTheme();
const CustomShadows = () => {
    const shadows = theme.shadows;
    return shadows;
};

export default CustomShadows;
