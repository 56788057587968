import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useMatch, useNavigate } from 'react-router';
import { ROUTES } from 'routes/const';


const settings = [
    {
        title: 'Account',
        url: '/account'
    },
    {
        title: 'Support',
        url: '/support'
    },
    {
        title: 'Logout',
        url: '/logout'
    }
];

const StyledSettingsMenuItem = styled(MenuItem)(({ theme }) => ({
    color: '#111',
    fontWeight: '500',
    '& a': {
        color: "#000",
        textDecoration: "none"
    }
}));

const MoreEsims = () => {
    const [anchor, setAnchor] = useState(null);

    const storePageMatch = useMatch({ path: ROUTES.STORE.INDEX, end: false });

    const navigate = useNavigate();

    const handleOpenMenu = (event) => {
        if (CONFIG.app.links.shopping_cart_link) {
            window.location.href = CONFIG.app.links.shopping_cart_link;
            return;
        }
        navigate(ROUTES.STORE.INDEX);
        //setAnchor(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchor(null);
    };
    return <><Button variant="contained" sx={{ mr: { md: 3, xs: 1 }, boxShadow: 0, backgroundColor: storePageMatch ? "#5E69A8" : "getEsims.main", fontSize: "14px", height: "56px" }} onClick={handleOpenMenu}>
        <Typography sx={{ display: { xs: "none", md: "inline" }, fontWeight: "600", whiteSpace: "nowrap", textTransform: "initial", color: "getEsims.contrastText" }}>Get More eSIMs</Typography>

    </Button>
        <Menu
            sx={{ mt: "56px", '& .MuiPaper-root': { width: "250px" } }}
            id="menu-appbar"
            disableScrollLock={true}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchor)}
            onClose={handleCloseMenu}
        >
            {settings.map((setting) => (
                <StyledSettingsMenuItem key={setting} onClick={handleCloseMenu}>
                    <Typography
                        component={NavLink}
                        to={setting.url}
                        textAlign="center">
                        {setting.title}
                    </Typography>
                </StyledSettingsMenuItem>
            ))}
        </Menu>
    </>
}

export default MoreEsims;