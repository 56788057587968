import { createSlice, current } from '@reduxjs/toolkit';
import TokenService from 'services/token/Token';

const user = TokenService.getData();

// initial state
const initialState = {
    avatar: user?.data?.avatarImageName
};

// TODO: Remove all user related info from local storage and use redux instead. Keep in local storage jwt tokens only.

const profile = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setAvatar(state, action) {
            state.avatar = action.payload;
            const profileData = TokenService.getData();
            //TokenService.setData({ ...profileData, data: { ...profileData.data, avatarImageName: action.payload } })
        },

        clearAvatar(state, action) {
            state.avatar = null;
            const profileData = TokenService.getData();
            // TokenService.setData({ ...profileData, data: { ...profileData.data, avatarImageName: null } })
        },
    }
});

export default profile.reducer;

export const { setAvatar, clearAvatar } = profile.actions;
