import { useAsyncNew } from 'hooks/useAsyncNew';
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import AccountService from 'services/account/Account';
import ClientsService from 'services/clients/Clients'

const defaultState = {};

const AvatarContext = createContext(defaultState)

export const AvatarProvider = ({ children }) => {

    const request = useAsyncNew(AccountService.getAvatar, {
        immediate: true,
        defaultState: {
            status: 'pending'
        }
    });

    return <AvatarContext.Provider value={request}>{children}</AvatarContext.Provider>
}

export const useAvatarContext = () => {
    const context = useContext(AvatarContext)
    if (context === undefined || context === null) {
        throw new Error(`useAvatarContext must be called within AccountProvider`)
    }
    return context
}