import { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';
import { Button, MenuItem, styled } from '@mui/material';
import Permission from 'components/permission';

const StyledMenuItem = styled(Button)(({ theme }) => ({
    display: 'block',
    color: theme.palette.menuMain.contrastText,
    fontWeight: '500',
    '&.active': {
        fontWeight: 700,
        color: theme.palette.menuMain.hoverText
    }
}));

export const DesktopMenu = ({ menu }) => {
    return <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, fontSize: '14px', letterSpacing: '0.02em' }}>
        {menu.map((menuItem) => (
            <StyledMenuItem
                sx={{ mx: { md: 2, xl: 3 }, my: 2, fontSize: '14px', fontWeight: '500' }}
                component={NavLink}
                to={menuItem.url}
                key={menuItem.title}>
                {menuItem.title}
            </StyledMenuItem>
        ))}
    </Box>
}

export const MobileMenu = ({ menu }) => {
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    return <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
        >
            <MenuIcon />
        </IconButton>
        <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
                backgroundColor: '#fff',
                display: { xs: 'block', md: 'none' },
            }}
        >
            {menu.map((menuItem) => (
                <MenuItem key={menuItem.title} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{menuItem.title}</Typography>
                </MenuItem>
            ))}
        </Menu>
    </Box>
}
